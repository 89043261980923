import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_18, fetch_pg_0_2, fetch_pg_0_20, fetch_pg_0_22, fetch_pg_0_3, Pg_0_1_Store, Pg_0_20_Store, Pg_0_22_Store} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chevronBack, chevronDownCircleOutline, diamondOutline} from "ionicons/icons";
import styles_Pg_0_2 from "./Pg_0_2.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import QRCode from "react-qr-code";
import styles_Pg_0_20 from "./Pg_0_20.module.scss";
import styles_Pg_0_22 from "./Pg_0_22.module.scss";
import {handleCommonFetchErrors, navToPg_0_22} from "./Utils";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {TokenItem} from "../components/TokenItem";
import moment from "moment";
import {StoneTransactionRow} from "../components/StoneTransactionRow";

const Pg_0_22 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_1";
    const pageId = "Pg_0_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_0_22({});
            handleCommonFetchErrors(router, errorObj);
        })();

    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_0_22({});
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_22_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_會員錢包流水 = hasLoaded ? pgData.Root.List_會員錢包流水 : undefined;
    //#endregion

    const click_showTransactions = async () => {
        navToPg_0_22(router);
        return false;
    }

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonRow>
                                <IonCol>時間</IonCol>
                                <IonCol>類別</IonCol>
                                <IonCol>寶石</IonCol>
                                <IonCol>結餘</IonCol>
                            </IonRow>

                            {List_會員錢包流水.map((item, index) => {
                                return <Fragment key={item.會員錢包流水Id}>
                                    <StoneTransactionRow Item_會員錢包流水={item}/>
                                </Fragment>
                            })}
                        </IonGrid>
                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_0_22;
