import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_2_1, fetch_pg_2_3, fetch_pg_2_4, Pg_2_1_Store, Pg_2_3_Store, Pg_2_4_Store, push_pg_2_4} from "../store/Pg_2_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {useEffect, useRef, useState, Suspense, lazy} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {TokenEnwDetailItem} from "../components/TokenEnwDetailItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import styles_TokenItem from "../components/TokenItem.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {ActionLink} from "../components/ActionLink";
import {fetch_pg_0_3, push_pg_0_3} from "../store/Pg_0_Store";
import {handleCommonFetchErrors, navToPg_2_1, navToPg_2_3} from "./Utils";
import styles_2_3 from './Pg_2_3.module.scss';

import {CallMe} from "../my-lib.mjs";
import Loading from "./Loading";
import {Modal_3_11_WriteComment} from "./Modal_3_11_WriteComment";
import {Modal_2_3_Transfer} from "./Modal_2_3_Transfer";
import {Modal_2_3_OpenCamera} from "./Modal_2_3_OpenCamera";
import {Modal_2_3_Entrust} from "./Modal_2_3_Entrust";
import {AuthStore} from "../store/Common_Store";
import styles_Modal_2_3_OpenCamera from "./Modal_2_3_OpenCamera.module.scss";
import {validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import {Pg_B_1_Store} from "../store/Pg_B_Store";
import {Pg_C_4_Store} from "../store/Pg_C_Store";

const Viewer3d = lazy(() => import("./Viewer3d"));
//const MyComponent = lazy(() => import("./MyComponent"));

const Pg_2_4 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_2_4";
    const pageId = "Pg_2_4";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;

    //#region Data Loading
    const {tokenEnwId}: any = useParams();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_2_4({
                p1: tokenEnwId,
            });
            handleCommonFetchErrors(router, errorObj);
        })();
    }, [tokenEnwId]);


    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_2_4({
            p1: tokenEnwId,
        });
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_2_4_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const Item_TokenEnw = hasLoaded ? pgData.Root.Item_TokenEnw : undefined;


    //#endregion

    let refTokenEnwDetailItem = useRef<any>(null);

    const _push_pg_2_4 = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let postData = await refTokenEnwDetailItem.current.gatherPostData();

        try {
            if (await push_pg_2_4({
                p1: Item_TokenEnw.TokenEnwId,
                ...postData,
            })) {
                //alert('更新成功')
                //reload();
            }

        } catch (err) {
        }

        navToPg_2_3(router,Item_TokenEnw.TokenEnwId);
    }


    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>


                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                <Suspense fallback={<Loading/>}>
                    {hasLoaded &&
                        <>
                            <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                {/*<IonRow>*/}
                                {/*    <IonCol size="12">*/}
                                {/*        <IonText color="dark">*/}
                                {/*            <p className="title">{Item_TokenEnw._Token.名稱}</p>*/}
                                {/*            <div className={styles_2_3.content} dangerouslySetInnerHTML={{__html: Item_TokenEnw._Token.介紹}}/>*/}
                                {/*            /!*<p>{Item_TokenEnw._Token.介紹}</p>*!/*/}
                                {/*        </IonText>*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}
                                <IonGrid className="ion-padding ion-margin-top ion-padding-top ios">
                                    {/*<IonRow className={ styles.searchContainer }>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}


                                    {/*click={() => handleShowModal(item)}*/}

                                    <TokenEnwDetailItem Item_TokenEnw={Item_TokenEnw} editMode={true} ref={refTokenEnwDetailItem}/>
                                    <IonRow>
                                        <IonCol size="12">
                                            <div>
                                                <IonButton fill={"solid"} expand="block" onClick={_push_pg_2_4}>
                                                    送出
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonGrid>
                        </>
                    }
                </Suspense>
            </CustomPage>
        </IonPage>
    );
};

export default Pg_2_4;
