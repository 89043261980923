import {IonButton, IonCheckbox, IonDatetime, IonDatetimeButton, IonInput, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonText, IonTextarea, RefresherEventDetail, useIonModal} from "@ionic/react";
import styles from "./CustomField.module.scss";
import React, {forwardRef, Fragment, useRef, useState} from "react";
import moment from "moment/moment";
import {Modal_3_1_WriteArticle} from "../pages/Modal_3_1_WriteArticle";
import queryString from "query-string";
import {fetch_pg_3_1} from "../store/Pg_3_Store";
import {handleCommonFetchErrors} from "../pages/Utils";
import {Modal_DatePicker} from "../pages/Modal_DatePicker";

type Props = {
    field: any
    isHideField?: boolean,
    errors: any,
    fieldType?: string,


    // children?: any
    noLabel?: boolean
    noLabelSlot?: boolean
    alternateLabel?: any
    className?: any
    inputClassName?: any
    alternateRightZone?: any
    alternateLeftZone?: any
    mainZone?: any
    disabled?: boolean
    textType?: string
    textTypeData?: any
    style?: any
};


// const Checkboxes_Bool = ({}: {}) => {
//     const refCheckbox1 = useRef<any>(null);
//     const refCheckbox2 = useRef<any>(null);
//
//     const onRef1 = (ref:any ) => {
//         refCheckbox1.current = ref;
//     }
//     const onRef2 = (ref:any ) => {
//         refCheckbox2.current = ref;
//     }
//
//     const onChange1 = (newVal:any) => {
//         debugger;
//         refCheckbox1.current.value = true;
//         refCheckbox2.current.value = false;
//     }
//
//     const onChange2 = (newVal:any) => {
//         debugger;
//         refCheckbox1.current.value = false;
//         refCheckbox2.current.value = true;
//     }
//
//     return <>
//         <IonItem className={styles.block}>
//             {/*<IonLabel>是</IonLabel>*/}
//             <IonCheckbox ref={onRef1} onClick={onChange1} onChange={onChange1} radioGroup="a"></IonCheckbox>
//             <IonLabel position="fixed">是</IonLabel>
//         </IonItem>
//         <IonItem className={styles.block}>
//
//             {/*<IonLabel>否</IonLabel>*/}
//             <IonCheckbox ref={onRef2} onClick={onChange2} onChange={onChange2} radioGroup="a"></IonCheckbox>
//             <IonLabel position="fixed">否</IonLabel>
//         </IonItem>
//     </>;
// }
const CustomField = forwardRef<any, any>(({
                                              field,
                                              isHideField = false,
                                              fieldType = 'auto',
                                              errors,

                                              // children = null,
                                              noLabel = false,
                                              noLabelSlot = true,
                                              className = null,
                                              inputClassName = "",
                                              alternateLabel = null,
                                              alternateRightZone = null,
                                              alternateLeftZone = null,

                                              disabled = false,
                                              textType = 'text',
                                              textTypeData = null,  // [{key: 1, value:'true', label:'是' }, x ]
                                              style,
                                          }: Props, ref) => {

            //#region +Events
            // let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
            //     window.scrollTo({
            //         top: 0,
            //         behavior: "smooth",
            //     });
            // }
            // const pageRef = useRef();
            // const [showModal, setShowModal] = useState(false);
            //
            // let ModalProps = {
            //     dismiss: () => {
            //         //dismissWebModal()
            //         setShowModal(false);
            //     },
            //     //文章Id: postId,
            //     //set: (val) => setQRData(val),
            //     onResult: (result) => {
            //         let {
            //             isSuccess,
            //             dates,
            //         } = result;
            //
            //         if (isSuccess === true) {
            //             ModalProps.dismiss();
            //             field.input.state.reset_WithCallback(dates);
            //             reload(null);
            //         }
            //     },
            //     reloadParent: reload,
            //     //product: selectedProduct
            // };
            // const [presentWebModal, dismissWebModal] = useIonModal(Modal_DatePicker, ModalProps);
            // const start = async () => {
            //     // const platforms = getPlatforms();
            //     // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
            //     //
            //     // if (!isWeb) {
            //     //     const data = await BarcodeScanner.scan();
            //     //     if (data) {
            //     //         handleSuccess(data);
            //     //     }
            //     // } else {
            //     //     presentWebModal({
            //     //         presentingElement: pageRef.current
            //     //     });
            //     // }
            //
            //     // presentWebModal({
            //     //     //cssClass: "product-modal",
            //     //     presentingElement: pageRef.current,
            //     //     initialBreakpoint: 0.50,
            //     //     breakpoints: [0, 0.25, 0.5, 0.75],
            //     // });
            //
            //     setShowModal(true);
            // }
            //#endregion


            if (!field) {
                return <>(ERROR:field)</>;
            }
            const error = errors && errors.filter(e => e.id === field.id)[0];
            const errorMessage = error && errors.filter(e => e.id === field.id)[0].message;

            if (field?.nodisplay === true) {
                return null;
            }

            let sidePlacedInput: any = <Fragment>
                {textType === 'textarea' ? <>
                    <IonTextarea className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} {...{'disabled': (disabled ? 'disabled' : null)}} />
                </> : <>
                    <IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} {...{'disabled': (disabled ? 'disabled' : null)}} />
                </>}
            </Fragment>;

            let mainZoneContent: any = null;
            {
                if (isHideField) {
                    mainZoneContent = <Fragment>
                        {alternateRightZone}
                    </Fragment>;
                } else {


                    if (textType === 'textarea') {
                        mainZoneContent = <>
                            <IonTextarea className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}/>
                            {alternateRightZone}
                        </>;
                    } else if (textType === 'radio') {
                        mainZoneContent = <>
                            <IonRadioGroup {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}>
                                {textTypeData.map((item) => {
                                    return <>
                                        <IonItem className={styles.itemZeroStart} key={item.key}>
                                            <IonRadio value={item.value}></IonRadio>
                                            <IonLabel position="fixed">{item.label}</IonLabel>
                                        </IonItem>
                                    </>;
                                })}
                            </IonRadioGroup>
                        </>
                    } else if (textType === 'dateTimeString') {
                        const myProps = {
                            ...field.input.props,
                            ...field.input.state
                        };


                        let value: any = myProps.value;

                        if (!field.input.props.hasInit) {
                            field.input.props.hasInit = true;
                            if (value === null) {
                                value = '';
                            } else {
                                let mDate = moment(value);
                                if (mDate.isValid()) {
                                    value = mDate.format('YYYY/MM/DD');
                                } else {
                                    value = '';
                                }
                            }
                        }

                        const onIonChange = (newVal) => {
                            myProps.reset(newVal.currentTarget.value);
                        }

                        mainZoneContent = <>
                            <IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} value={value} onIonChange={null} onIonBlur={onIonChange} disabled={(disabled ? 'disabled' : null)}/>
                            {alternateRightZone}
                        </>
                    } else if (textType === 'datePicker') {
                        const myProps = {
                            ...field.input.props,
                            ...field.input.state
                        };


                        let value: any = myProps.value;

                        mainZoneContent = <>
                            <IonDatetimeButton datetime="datetime" className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime
                                    id="datetime"
                                    presentation="date"
                                    {...field.input.props} {...field.input.state}
                                    //value="2023-11-02T01:22:00"
                                    formatOptions={{
                                        date: {
                                            weekday: 'short',
                                            month: 'long',
                                            day: '2-digit',
                                        },
                                        // time: {
                                        //     hour: '2-digit',
                                        //     minute: '2-digit',
                                        // },
                                    } as any}
                                ></IonDatetime>
                            </IonModal>

                            {/*<IonButton className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)} onClick={start}>*/}
                            {/*    /!*{moment(value).isValid() ? moment(value).format('YYYY/MM/DD') : '-'}*!/*/}
                            {/*    選取一個時間*/}
                            {/*</IonButton>*/}

                            {/*{ showModal &&*/}
                            {/*    <Modal_DatePicker {...ModalProps}/>*/}
                            {/*}*/}
                            {/*<IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}/>*/}
                            {alternateRightZone}
                        </>
                    } else {
                        mainZoneContent = <>
                            <IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}/>
                            {alternateRightZone}
                        </>;
                    }
                }
            }

            if (alternateLeftZone !== null || alternateRightZone !== null) {
                sidePlacedInput = <Fragment>
                    <div>
                        {!noLabelSlot &&
                            <div slot="label">
                                {field.label} {field.required ? <IonText color="danger">(必填)</IonText> : null}
                            </div>
                        }
                        <div className={styles.rowsContainer}>
                            {alternateLeftZone}
                            {mainZoneContent}
                        </div>
                    </div>
                </Fragment>;
            } else {
                sidePlacedInput = <Fragment>
                    {!noLabelSlot &&
                        <div slot="label">
                            {field.label} {field.required ? <IonText color="danger">(必填)</IonText> : null}
                        </div>
                    }
                    {mainZoneContent}
                </Fragment>;
            }

            return (
                <>
                    <div className={className || styles.defaultFieldWrapper} {...{style: style}}>
                        <div className={styles.field}>
                            {/*{!noLabel && (alternateLabel ||*/}
                            {/*    <IonLabel>*/}
                            {/*        {field.label}*/}
                            {/*        {error && <p className="animate__animated animate__bounceIn">{errorMessage}</p>}*/}
                            {/*    </IonLabel>*/}
                            {/*)}*/}
                            {!noLabel && error && (
                                <IonLabel>
                                    <span>&nbsp;</span>
                                    {<p className="animate__animated animate__bounceIn">{errorMessage}</p>}
                                </IonLabel>
                            )}
                            {sidePlacedInput}
                        </div>
                    </div>
                </>
            );
        }
    )
;

export default CustomField;
