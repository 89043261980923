import {Store} from "pullstate";
import _ from "../../src/_/index";
import {UserCouponBookmarkStore, UserTokenEntrustStore, UserTokenFavorStore} from "./Common_Store";
import indexBy from "underscore/modules/indexBy";
import {handleExecutor} from "../pages/Utils";

export const Pg_1_1_Store = new Store({
    quotes: [],
    saved: [],
});

export const Pg_1_2_Store = new Store({});

export const Pg_1_5_Store = new Store({});

export const Pg_1_6_Store = new Store({});
export const Pg_1_6_1_Store = new Store({});

export const Pg_1_12_Store = new Store({});

export const Pg_1_12_1_Store = new Store({});

let fetchOption = (postParams) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_Pg_1_1 = async () => {
    let postParams = {
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_1_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_1_Store.update(s => {
            return Object.assign(s, payload)
        });

        await handleExecutor(payload.__Executor);
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_1_2 = async (postParams: {
    p1: string //contractId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_1_2`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_2_Store.update(s => {
            return Object.assign(s, payload)
        });

        if (payload.Root.Attach_List_Token__已發行_List_會員_Token__關注) {
            let keyed = indexBy(payload.Root.Attach_List_Token__已發行_List_會員_Token__關注, 'TokenId');

            UserTokenFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }

        if (payload.Root.Attach_List_Token__即將發行_List_會員_Token__關注) {
            let keyed = indexBy(payload.Root.Attach_List_Token__即將發行_List_會員_Token__關注, 'TokenId');

            UserTokenFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}

export const fetch_pg_1_5 = async (postParams: {
    p1: string //tokenId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_1_5`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_5_Store.update(s => {
            return Object.assign(s, payload)
        });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_1_6 = async (postParams: {
    p1: string //tokenId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_1_6`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_6_Store.update(s => {
            return Object.assign(s, payload)
        });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const fetch_pg_1_12 = async (postParams: {
    p1: string //tokenId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_1_12`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_12_Store.update(s => {
            return Object.assign(s, payload)
        });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const push_pg_1_6__Buy = async (postParams: {
    p1: string,
    p4: string,
}, onDone: ((result: any) => void) | null = null) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_1_6/buy`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_6_Store.update(s => {
            return Object.assign(s, payload)
        });
        if (onDone) {
            await onDone(payload);
        }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_1_12__Buy = async (postParams: {
    p1: string,
    p4: string,
}, onDone: ((result: any) => void) | null = null) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_1_12/buy`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_1_6_Store.update(s => {
            return Object.assign(s, payload)
        });
        if (onDone) {
            await onDone(payload);
        }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_1_2__AddTokenFavor = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_1_2/addtokenfavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserTokenFavorStore.update(s => {
            return Object.assign(s, payload)
        });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_1_2__RemoveTokenFavor = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_1_2/removetokenfavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserTokenFavorStore.update(s => {
            return Object.assign(s, payload)
        });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}
