import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_1_5, fetch_pg_1_6, Pg_1_5_Store, Pg_1_6_1_Store, Pg_1_6_Store, push_pg_1_6__Buy} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import CustomPage from "./CustomPage";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {handleCommonFetchErrors, navToPg_0_18} from "./Utils";

const Pg_1_6_1 = (props) => {
        const pageRef = useRef();
        const pageName = "Pg_1_6_1";
        const pageId = "Pg_1_6_1";
        // const {sideMenuOptions} = props;
        // const setSideMenu: any = useSideMenuUpdate();
        // const sideMenu: any = useSideMenuOptions();

        const router = useIonRouter();

        //#region Data Loading
        const {tokenId}: any = useParams();

        // useEffect(() => {
        //     (async () => {
        //         let errorObj = await fetch_pg_1_6({
        //             p1: tokenId,
        //         });
        //         handleFetchCommonErrors(router, errorObj);
        //     })();
        // }, []);
        //
        // let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: "smooth",
        //     });
        //     let errorObj = await fetch_pg_1_6({
        //         p1: tokenId,
        //     });
        //     handleFetchCommonErrors(router, errorObj);
        //     event?.detail?.complete();
        // }

        const pgData = useStoreState(Pg_1_6_1_Store, getPageData);

        const hasLoaded = pgData?.Root ?? false;
        const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;
        const Item_盲盒 = hasLoaded ? pgData.Root.Item_盲盒 : undefined;
        const List_Token = hasLoaded ? pgData.Root.List_Token : undefined;

        //#endregion
        const click_checkInventory = async () => {
            navToPg_0_18(router);
            return false;
        }

        const _push_pg_1_6__Buy = async (tokenId) => {
            let errorObj = (await push_pg_1_6__Buy({
                    p1: tokenId,
                    p4: window.location.href,
                }, (result) => {
                    window.location.href = result.RedirectTo;
                    //router.push(result.RedirectTo);
                })
            );
            handleCommonFetchErrors(router, errorObj);
            if (!errorObj) {
                //alert('購買成功');
                //navToPg_A_1(router);
            } else {
            }
        }

        return (
            <IonPage id={pageId}>
                <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>

                    {/*<IonRefresher slot="fixed" onIonRefresh={reload}>*/}
                    {/*    <IonRefresherContent*/}
                    {/*        pullingIcon={chevronDownCircleOutline}*/}
                    {/*        pullingText="Pull to refresh"*/}
                    {/*        refreshingSpinner="circles"*/}
                    {/*        refreshingText="Refreshing...">*/}
                    {/*    </IonRefresherContent>*/}
                    {/*</IonRefresher>*/}

                    <IonGrid className="animate__animated animate__slideInRight animate__faster ion-text-center">
                        <IonRow>
                            <IonCol>
                                您已完成購買，商品已歸戶
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>

                                <IonButton fill={"solid"} expand="block" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    click_checkInventory();

                                    return false;
                                }}>
                                    查看收藏交易紀錄
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        {/*<IonRow>*/}
                        {/*    <IonCol>*/}
                        {/*        <IonText color="dark">*/}
                        {/*            <p className="title">盲盒內容</p>*/}
                        {/*        </IonText>*/}
                        {/*    </IonCol>*/}
                        {/*</IonRow>*/}
                        {/*<IonRow className={ styles.searchContainer }>*/}
                        {/*    <IonCol size="12">*/}
                        {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                        {/*    </IonCol>*/}
                        {/*</IonRow>*/}
                        {/**/}
                        {/*<div className={styles_TokenItem.coverImageContainer}>*/}
                        {/*    {List_Token.map((item, index) => {*/}
                        {/*        return <Fragment key={item.TokenId}>*/}
                        {/*            <TokenItem Item_Token={item}/>*/}
                        {/*        </Fragment>*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </IonGrid>

                    {/*{hasLoaded &&*/}
                    {/*    <>*/}
                    {/*    </>*/}
                    {/*}*/}
                </CustomPage>


            </IonPage>
        );
    }
;

export default Pg_1_6_1;
