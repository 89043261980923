import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_18, fetch_pg_0_2, fetch_pg_0_20, fetch_pg_0_22, fetch_pg_0_3, Pg_0_1_Store, Pg_0_20_Store, Pg_0_22_Store} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";

import styles_Pg_1_25 from "./Pg_1_25.module.scss";
import {ActionLink} from "../components/ActionLink";

const Pg_1_25 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_1_25";
    const pageId = "Pg_1_25";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <>
                    <IonGrid className={`pad-0 ${styles_Pg_1_25.p}`}>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonImg src={"/assets/FrontPage/1.png"}/>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonCol>
                                <p></p>
                                <div>
                                    <IonText>PET JOURNEY寵旅護照是一項全球限量的寵物專屬身份認證，結合區塊鏈數位資產憑證技術，確保所有上傳的圖片、影像和文字敘述的安全性和不可竄改性。每個護照內含一個元宇宙寵物孵化器，作為永久的數位資產記錄，並象徵寵物身份的獨特性。</IonText>
                                </div>
                                <p></p>
                                <div>
                                    <IonText><a href="https://www.petjourney.ai/tabs/3_11/2504">⇢ 免費註冊會員並為愛寵取得第一本寵旅護照</a></IonText>
                                </div>
                                <p></p><p></p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonImg src={"/assets/FrontPage/2.png"}/>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonCol>
                                <p></p>
                                <div>
                                    <IonText>元宇宙寵物孵化器是一個專為紀錄和保存寵物記憶的平台。用戶可以上傳寵物的圖像、影片及文字，並利用AI技術生成寵物寫真。這些數位分身將作為寵物的AI助理，隨時陪伴用戶，就像鋼鐵人中的Jarvis。未來，當寵物離世後，孵化器將生成其虛擬分身，永遠陪伴主人，延續彼此的情感。同時，CROSS品牌聯名孵化器中，品牌可定制專屬的AstroPup和MystiCat，結合品牌特色與設計，讓用戶在元宇宙中體驗品牌聯名的獨特互動，提升品牌的沉浸感與影響力。</IonText>
                                </div>
                                <p></p>
                                <div>
                                    <IonText><a href="https://www.petjourney.ai/tabs/3_11/2505">⇢ 暸解更多關於元宇宙孵化器</a></IonText>
                                </div>
                                <div>
                                    <IonText><ActionLink link={`/tabs/1_1`}>⇢ Cross品牌聯名孵化器</ActionLink></IonText>
                                </div>
                                <p></p><p></p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonImg src={"/assets/FrontPage/3.png"}/>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonCol>
                                <p></p>
                                <div>
                                    <IonText>PET JOURNEY平台提供先進的WebXR互動功能，讓用戶可以透過瀏覽器直接體驗虛擬寵物孵化器的魅力，無需額外的應用程式或設備。使用手機或平板，您可以與虛擬的孵化器和寵物進行互動，甚至與真實寵物同框拍照，增強了互動性和趣味性。</IonText>
                                </div>
                                <p></p>
                                <div>
                                    <IonText><a href="https://www.petjourney.ai/tabs/3_11/2506">⇢ 如何使用手機呼叫寵物孵化器進行互動拍照與錄影</a></IonText>
                                </div>
                                <p></p><p></p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonImg src={"/assets/FrontPage/4.png"}/>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonCol>
                                <p></p>
                                <div>
                                    <IonText>平台引入由midjourney.ai提供的AI技術進行寵物寫真生成，並已完成中文化處理，讓用戶無需繁複操作即可輕鬆創作獨特的寵物藝術作品。這項功能提供簡化的操作界面，支持多種藝術風格選擇，為您的寵物打造獨一無二的寵物寫真。</IonText>
                                </div>
                                <p></p>
                                <div>
                                    <IonText><a href="https://www.petjourney.ai/tabs/3_11/2509">⇢ 暸解如何用AI為您的愛寵打造寫真集</a></IonText>
                                </div>
                                <p></p><p></p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonImg src={"/assets/FrontPage/5.png"}/>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonCol>
                                <p></p>
                                <div>
                                    <IonText>陪著你寵物社群 PET JOURNEY SOCIAL是一個為全球寵物愛好者打造的互動平台。在這裡，用戶可以分享家中愛寵的日常生活、照片和視頻，與其他寵物家庭成員交流互動，分享養寵心得。該社群還會定期舉辦線上活動和挑戰賽，讓用戶能夠增進彼此間的情感聯繫，並參與更多元的寵物文化交流。此外，平台將根據用戶的參與度和互動情況提供專屬獎勵和優惠，增強用戶體驗。</IonText>
                                </div>
                                <p></p>
                                <div>
                                    <IonText><a href="https://www.petjourney.ai/tabs/3_11/2510">⇢ 加入社群認識新朋友</a></IonText>
                                </div>
                                <p></p><p></p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </>
            </CustomPage>
        </IonPage>
    );
};

export default Pg_1_25;
