import {getPlatforms, IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {bookmarkOutline, checkmarkOutline, chevronDownCircleOutline, copyOutline, shieldCheckmarkSharp} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {PageHeader} from "./PageHeader";
import styles from "./Categories.module.scss";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {fetch_pg_1_5, Pg_1_5_Store} from "../store/Pg_1_Store";
import {validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import {push_pg_C_1} from "../store/Pg_C_Store";
import {Modal_2_1} from "./Modal_2_1";
import {useLoginFields} from "./Pg_A_1";
import styles_2_1 from './Pg_2_1.module.scss';
import styles_TokenItem from "../components/TokenItem.module.scss";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleCommonFetchErrors, navToPg_0_18, navToPg_2_1, navToPg_2_13, promptBrowserToLogin} from "./Utils";
import {ActionLink} from "../components/ActionLink";
import styles_4_2 from "./Pg_4_2.module.scss";
import {ContractBonusItem} from "../components/ContractBonusItem";
import styles_Common from "./Common.module.scss";
import {AuthStore} from "../store/Common_Store";

const Pg_2_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_2_1";
    const pageId = "Pg_2_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    //const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    ///#endregion

    useEffect(() => {
        setSideMenu({options: {}, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_2_1();
            handleCommonFetchErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_2_1();
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_2_1_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const List_TokenEnw = hasLoaded ? pgData.Root.List_TokenEnw : undefined;
    const Item_會員 = hasLoaded ? pgData.Box_Header.Item_會員 : undefined;
    //#endregion

    //#region Modal
    const [showModal, setShowModal] = useState<any>(false);
    const [modalOptions, setModalOptions] = useState<any>(false);

    const handleModal = async (index: any) => {
        setShowModal(false);
        await setModalOptions(tab1SideMenu[index]);
        setShowModal(true);
    }

    const [QRData, setQRData] = useState(false);

    const [present, dismiss] = useIonModal(Modal_2_1, {
        dismiss: () => dismiss(),
        code: QRData,
        set: (val) => setQRData(val),
        scan: () => start()
    });

    const handleScan = result => {
        if (result) {
            setQRData(result);
            //play();
            handleSuccess(result);
        }
    }
    const handleError = err => {

    }

    const handleOnResult = result => {
        //navToPg_0_18(router);
        if (result) {
            reload(null);
        }
    }

    const handleSuccess = data => {
        setQRData(data);
        alert(data);
        console.log(data);
        dismissWebModal();

        //play();
        // present({
        //     presentingElement: pageRef.current
        // });
    }

    const [presentWebModal, dismissWebModal] = useIonModal(Modal_2_1, {
        dismiss: () => dismissWebModal(),
        //set: (val) => setQRData(val),
        //onResult: (result) => handleScan(result),
        onResult: (result) => handleOnResult(result),
        //product: selectedProduct
    });

    const start = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal({
        //         presentingElement: pageRef.current
        //     });
        // }
        if (!hasAuthed) {
            promptBrowserToLogin();
            return;
        }

        presentWebModal({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.95,
            breakpoints: [0, 0.25, 0.5, 0.75, 0.95],
        });
    }

    //#endregion

    //#region +Event

    //#region click_收藏品兌換
    const click_我的最愛 = async () => {
        navToPg_2_13(router);
        return false;
    }

    const click_我的收藏 = async () => {
        navToPg_2_1(router);
        return false;
    }
    //#endregion

    //#endregion


    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <div onClickCapture={(ev)=> {
                        if (!hasAuthed) {
                            promptBrowserToLogin();
                            ev.stopPropagation();
                            return false;
                        }
                        return true;
                    }}>
                        {/*<IonGrid>*/}
                        {/*    <IonRow>*/}
                        {/*        <IonCol>*/}
                        {/*            <IonSegment value="我的收藏">*/}
                        {/*                <IonSegmentButton value="我的收藏">*/}
                        {/*                    <IonLabel>我的收藏</IonLabel>*/}
                        {/*                </IonSegmentButton>*/}
                        {/*                <IonSegmentButton value="我的最愛" onClick={click_我的最愛}>*/}
                        {/*                    <IonLabel>我的最愛</IonLabel>*/}
                        {/*                </IonSegmentButton>*/}
                        {/*            </IonSegment>*/}
                        {/*        </IonCol>*/}
                        {/*    </IonRow>*/}
                        {/*</IonGrid>*/}


                        {Item_會員 &&
                        <div className={`${styles_2_1.avatarContainer}`}>
                            <div>
                                <IonAvatar className="avatar">
                                    {Ln_TokenPictureSetCoverImg({
                                        圖片組: Item_會員.個人照片,
                                        名稱: 'cover',
                                    })}
                                </IonAvatar>
                            </div>
                            <div>
                                <h6>{Item_會員.暱稱}{Item_會員.藍標 && <IonIcon icon={shieldCheckmarkSharp} className={styles_Common.userNameBlueIcon}></IonIcon>}</h6>
                            </div>
                        </div>
                        }
                        {!Item_會員 &&
                            <div className={`${styles_2_1.avatarContainer}`}>
                                <div>
                                    <IonAvatar className="avatar">
                                        {Ln_TokenPictureSetCoverImg({
                                            名稱: 'cover',
                                        })}
                                    </IonAvatar>
                                </div>
                                <div>
                                    <h6>未登錄</h6>
                                </div>
                            </div>
                        }

                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <IonGrid>
                                <IonRow>
                                    <p>{Item_會員?.個人介紹 ?? ""}</p>
                                </IonRow>
                            </IonGrid>

                            <IonRow className="ion-justify-content-center ion-text-center w100">
                                <IonCol>
                                    <IonButton fill={"solid"} onClick={() => start()} expand="block">
                                        寵旅護照兌換
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        {List_TokenEnw.length === 0 ?
                            <>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                            請使用序號兌換寵旅護照。
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </> : <>
                                <IonGrid className="ion-padding">
                                    {/*<IonRow className={ styles.searchContainer }>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}

                                    <div className={styles_TokenItem.coverImageContainer}>
                                        {List_TokenEnw.map((item, index) => {
                                            return <Fragment key={item.TokenEnwId}>
                                                <TokenEnwItem Item_TokenEnwItem={item}/>
                                            </Fragment>
                                        })}
                                    </div>
                                </IonGrid>
                            </>}

                    </div>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_2_1;
