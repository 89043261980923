import {IonAvatar, IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./TokenEnwTradeItem_BuyIn.module.scss";
import {bookmark, bookmarkOutline, cartOutline, cashOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, star, timeOutline} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles_Common from "../pages/Common.module.scss";
import moment from "moment";
import {useStoreState} from "pullstate";
import {UserCouponBookmarkStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";
import {push_pg_3_11__AddArticleFavor, push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveArticleFavor} from "../store/Pg_3_Store";
import {push_pg_4_2__AddCouponBookmark, push_pg_4_2__RemoveCouponBookmark} from "../store/Pg_4_Store";


//#region +Event
// const navToPg_1_1 = () => {
//     router.push("/tabs/1_1", "forward", "push");
// }

//#region push register
const _push_pg_3_11__AddCommentFavor = async (commentId) => {
    if (await push_pg_3_11__AddCommentFavor({
        p1: commentId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_4_2__AddCouponBookmark = async (postId) => {
    if (await push_pg_4_2__AddCouponBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_4_2__RemoveCouponBookmark = async (postId) => {
    if (await push_pg_4_2__RemoveCouponBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

//#endregion

//#endregion

export const TokenEnwTradeItem_BuyIn = ({Item_TokenEnw交易, fromHome = false, noLink = false}) => {
    const router = useIonRouter();
    const couponBookmarks = useStoreState(UserCouponBookmarkStore, getPageData);

    let Item_會員公開__買方: any = Item_TokenEnw交易?._會員公開__買方;
    // if (!Item_會員公開__買方) {
    //     return <>(ERROR:Item_會員公開__買方)</>;
    // }

    let Item_會員公開__賣方: any = Item_TokenEnw交易?._會員公開__賣方;
    // if (!Item_會員公開__賣方) {
    //     return <>(ERROR:Item_會員公開__賣方)</>;
    // }

    let Item_Token: any = Item_TokenEnw交易?._Token;
    if (!Item_Token) {
        return <>(ERROR:Item_Token)</>;
    }

    let Item_TokenEnw: any = Item_TokenEnw交易?._TokenEnw;
    if (!Item_TokenEnw) {
        return <>(ERROR:Item_TokenEnw)</>;
    }

    return (
        <ActionLink link={`/tabs/1_5/${Item_Token.TokenId}`} className={styles.container} noLink={noLink}>
            <div className={styles.containerInner}>


                <div className={styles.postBar}>
                    <div className="l">
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: Item_Token.圖片組,
                            名稱: 'cover',
                        })}
                    </div>
                    <div className="r">
                        <div className="rInner">
                            <div className={styles.nameBar}>
                                <div className="container">
                                    <div className="l">
                                        {Item_Token.名稱}
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_Token_會員公開.暱稱}*/}
                                    {/*</div>*/}
                                    {/*<div className="r2">*/}
                                    {/*    <div className="roundbg">*/}
                                    {/*        {Item_Token.名稱}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.priceBar}>
                                <div className="container">
                                    {/*<div className="l">*/}
                                    {/*    {Item_Token.名稱}*/}
                                    {/*</div>*/}
                                    <div className="salePrice">
                                        <div className="l">
                                            <div className="indent"><IonIcon icon={cashOutline}/></div>
                                            購買價格
                                        </div>
                                        <div className="r">{Item_TokenEnw交易.價金} 寶石</div>
                                    </div>


                                    {/*<div className="r">*/}
                                    {/*    {Item_Token_會員公開.暱稱}*/}
                                    {/*</div>*/}
                                    {/*<div className="r2">*/}
                                    {/*    <div className="roundbg">*/}
                                    {/*        {Item_Token.名稱}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className={styles.timeBar}>
                                <div className="l">
                                    <div className="indent"><IonIcon icon={timeOutline}/></div>
                                    時間
                                </div>
                                <div className="r">
                                    {moment(Item_TokenEnw交易.交易時間).format('YYYY/MM/DD hh:mm')}
                                </div>
                            </div>
                            <div className={styles.memoBar}>
                                {Item_TokenEnw交易.交易型態 === '買賣' &&
                                    <>
                                        <div className="l">
                                            購買自
                                        </div>
                                        <div className="r">
                                            {Item_會員公開__賣方?.暱稱 ?? "新品商店"}
                                        </div>
                                    </>}
                                {Item_TokenEnw交易.交易型態 === '兌換' &&
                                    <>
                                        <div className="l">
                                            兌換自
                                        </div>
                                        <div className="r">
                                            {Item_會員公開__賣方?.暱稱 ?? "系統"}
                                        </div>
                                    </>}
                                {Item_TokenEnw交易.交易型態 === '轉移' &&
                                    <>
                                        <div className="l">
                                            轉移自
                                        </div>
                                        <div className="r">
                                            {Item_會員公開__賣方?.暱稱 ?? "系統"}
                                        </div>
                                    </>}

                            </div>
                        </div>
                    </div>
                </div>


                {/*<div><p>{capitalize(item.Name)}</p></div>*/}
            </div>

        </ActionLink>
    );
}
