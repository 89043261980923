import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_3, Pg_0_1_Store, Pg_0_3_Store, push_pg_0_3} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chevronDownCircleOutline} from "ionicons/icons";
import styles_Pg_0_2 from "./Pg_0_2.module.scss";
import styles_Pg_0_3 from "./Pg_0_3.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import QRCode from "react-qr-code";
import {useFormInput, validateForm} from "../_/useForms";
import CustomField from "../components/CustomField";
import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {Pg_B_1_Store, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {Pg_C_4_Store, push_pg_C_1, push_pg_C_4_phoneverify, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {useParams} from "react-router";
import indexBy from "underscore/modules/indexBy";
import {handleCommonFetchErrors} from "./Utils";
import Uploady, {useBatchAddListener, useItemProgressListener, useRequestPreSend} from "@rpldy/uploady";
import ChunkedUploady, {UploadyContext, useBatchFinishListener, useChunkStartListener} from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import AddCircleOutlineSharp from "@mui/icons-material/AddCircleOutlineSharp";
import Button from '@mui/material/Button';
import {v4 as uuidv4} from "uuid";
import MyUploadProgress from "./MyUploadProgress";
import MyUploadFail from "./MyUploadFail";
import {constAreaCodeList} from "../_/constant";

const MAX_FILE_SIZE = 99000000;
export const MyUploadButton = ({setUploadedFile}) => {
    const uploady = useContext(UploadyContext);
    const [fileId, set_fileId] = useState("");

    useRequestPreSend(({items}) => {
        const _fileID = uuidv4() + "." + items[0].file.name.split(".").pop();
        set_fileId(_fileID);
        return {
            options: {
                destination: {
                    params: {
                        fileId: fileId
                    }
                }
            }
        };
    });
    useChunkStartListener(({chunk, chunkItem}) => {
        return {
            sendOptions: {
                method: "POST",
                paramName: "data",
                params: {
                    // chunkId: chunk?.index,
                    fileSize: chunkItem?.file?.size
                }
            }
        };
    });
    useBatchAddListener((batch) => {
        let result;
        if (batch?.items[0]?.file?.size > MAX_FILE_SIZE) {
            result = false; //false will cancel.
        }
        return result;
    });
    useBatchFinishListener((batch) => {
        if (batch?.items[0]?.state === "finished") {
            // saveUploadedDocumentToProject({fileName: batch?.items[0]?.file.name,fileId: fileID,});

            let fileId_2 = JSON.parse(batch.items[0].uploadResponse.results[0].data).files[0].id;
            //console.log(fileId);

            setUploadedFile(fileId_2);
        }
    });
    const handleUpload = useCallback(() => {
        uploady.showFileUpload();
    }, [uploady]);
    return (
        <Button onClick={handleUpload}>
            <AddCircleOutlineSharp/>
            <span>&nbsp;&nbsp;更換圖片</span>
        </Button>
    );
};


const customSelectContainerOptions = {
    header: '手機國際碼',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};



// export const useRegisterFields = () => {
//     return [
//         {
//             id: "scodeEmail",
//             nodisplay: true,
//             label: "scodeEmail",
//             required: false,
//             value: null,
//         },
//         {
//             id: "vcodeEmail",
//             nodisplay: false,
//             label: "vcodeEmail",
//             required: false,
//             input: {
//                 props: {
//                     type: "email",
//                     placeholder: "",
//                 },
//                 state: useFormInput("")
//             }
//         },
//         {
//             id: "scodePhone",
//             nodisplay: true,
//             label: "scodePhone",
//             required: false,
//             value: null,
//         },
//         {
//             id: "vcodePhone",
//             nodisplay: false,
//             label: "vcodePhone",
//             required: false,
//             input: {
//                 props: {
//                     type: "email",
//                     placeholder: "",
//                 },
//                 state: useFormInput("")
//             }
//         },
//
//         {
//             id: "電子郵件",
//             label: "電子郵件",
//             required: true,
//             input: {
//                 props: {
//                     type: "email",
//                     placeholder: "",
//                     autocomplete: "username",
//                 },
//                 state: useFormInput("raymond0321@gmail.com")
//             }
//         },
//         {
//             id: "手機國碼",
//             label: "手機國碼",
//             required: true,
//             input: {
//                 props: {
//                     type: "text",
//                     placeholder: "",
//
//                 },
//                 state: useFormInput("121212")
//             }
//         },
//         {
//             id: "手機號碼",
//             label: "手機號碼",
//             required: true,
//             input: {
//                 props: {
//                     type: "text",
//                     placeholder: "",
//                     autocomplete: "tel-local"
//                 },
//                 state: useFormInput("121212")
//             }
//         },
//         {
//             id: "登入密碼",
//             label: "密碼",
//             required: true,
//             input: {
//                 props: {
//                     type: "password",
//                     placeholder: "",
//                     autocomplete: "new-password"
//                 },
//                 state: useFormInput("121212")
//             }
//         },
//         {
//             id: "個人介紹",
//             label: "個人介紹",
//             required: true,
//             input: {
//                 props: {
//                     type: "text",
//                     placeholder: "",
//                     rows: 3,
//                     autoGrow: true,
//                 },
//                 state: useFormInput("121212")
//             }
//         },
//         {
//             id: "寄送地址",
//             label: "寄送地址",
//             required: true,
//             input: {
//                 props: {
//                     type: "text",
//                     placeholder: "",
//                     rows: 3,
//                     autoGrow: true,
//                 },
//                 state: useFormInput("121212")
//             }
//         },
//     ];
// }

const RenderForm = ({router, Item_會員, reload}) => {
    const [errors, setErrors] = useState(false);
    const [emailVerifyStage, setEmailVerifyStage] = useState<number>(0);
    const [phoneVerifyStage, setPhoneVerifyStage] = useState<number>(0);


    const [uploadedFile, setUploadedFile] = useState(Item_會員.個人照片);


    //#region +Event
    const fields = [
        {
            id: "scodeEmail",
            nodisplay: true,
            label: "scodeEmail",
            required: false,
            value: null,
        },
        {
            id: "vcodeEmail",
            nodisplay: false,
            label: "vcodeEmail",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },
        {
            id: "scodePhone",
            nodisplay: true,
            label: "scodePhone",
            required: false,
            value: null,
        },
        {
            id: "vcodePhone",
            nodisplay: false,
            label: "vcodePhone",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },

        {
            id: "電子郵件",
            label: "電子郵件",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "電子郵件",
                    autocomplete: "username",
                },
                state: useFormInput(Item_會員.Email, (val, oldVal) => {
                    if (val === oldVal) {
                        setEmailVerifyStage(0);
                    } else {
                        setEmailVerifyStage(1);
                    }
                })
            }
        },
        {
            id: "手機國碼",
            label: "手機國際碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機國際碼",
                },
                state: useFormInput(Item_會員.手機國碼, (val, oldVal) => {

                })
            }
        },
        {
            id: "手機號碼",
            label: "手機號碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機號碼",
                    autocomplete: "tel-local"
                },
                state: useFormInput(Item_會員.手機, (val, oldVal) => {

                })
            }
        },
        {
            id: "登入密碼",
            label: "密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "密碼",
                    autocomplete: "new-password"
                },
                state: useFormInput(Item_會員.登入密碼)
            }
        },
        {
            id: "個人介紹",
            label: "個人介紹",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "個人介紹",
                    rows: 3,
                    autoGrow: true,
                },
                state: useFormInput(Item_會員.個人介紹)
            }
        },
        {
            id: "寄送地址_收件人",
            label: "收件人",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "收件人",
                    rows: 3,
                    autoGrow: true,
                },
                state: useFormInput(Item_會員.CardSendReceiver)
            }
        },
        {
            id: "寄送地址_連絡電話",
            label: "連絡電話",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "連絡電話",
                    rows: 3,
                    autoGrow: true,
                },
                state: useFormInput(Item_會員.CardSendContactPhone)
            }
        },
        {
            id: "寄送地址_收件地址",
            label: "收件地址",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "收件地址",
                    rows: 3,
                    autoGrow: true,
                },
                state: useFormInput(Item_會員.CardSendAddress)
            }
        },
        {
            id: "暱稱",
            label: "顯示名稱",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "顯示名稱",
                    rows: 3,
                    autoGrow: true,
                },
                state: useFormInput(Item_會員.暱稱)
            }
        },
    ];

    let keyedFields = indexBy(fields, 'id');

    useEffect(() => {
        if (`${keyedFields.手機號碼.input.state.initialValue}${keyedFields.手機國碼.input.state.initialValue}` ===
            `${keyedFields.手機號碼.input.state.value}${keyedFields.手機國碼.input.state.value}`) {
            setPhoneVerifyStage(0);
        } else {
            setPhoneVerifyStage(1);
        }
    }, [keyedFields.手機號碼.input.state.value, keyedFields.手機國碼.input.state.value]);

    //#region push register
    const _push_pg_0_3 = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
            //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
            //const scodePhone = fields && (keyedFields.scodePhone as any).value;
            //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;

            let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;
            let {phone, scodePhone, vcodePhone} = Pg_C_4_Store.getRawState() as any;

            if (emailVerifyStage !== 4 && emailVerifyStage !== 0) {
                alert("因為電子信箱改變過，請再次先完成Email驗證流程");
                return;
            }
            if (phoneVerifyStage !== 4 && phoneVerifyStage !== 0) {
                alert("因為手機號改變過，請再次先完成手機驗證流程");
                return;
            }

            const 電子郵件 = fields && (keyedFields.電子郵件 as any).input.state.value;

            const 登入密碼 = fields && (keyedFields.登入密碼 as any).input.state.value;
            const 手機國碼 = fields && (keyedFields.手機國碼 as any).input.state.value;
            const 手機號碼 = fields && (keyedFields.手機號碼 as any).input.state.value;

            const 個人介紹 = fields && (keyedFields.個人介紹 as any).input.state.value;
            const 個人照片 = uploadedFile;

            const 寄送地址_收件人 = fields && (keyedFields.寄送地址_收件人 as any).input.state.value;
            const 寄送地址_連絡電話 = fields && (keyedFields.寄送地址_連絡電話 as any).input.state.value;
            const 寄送地址_收件地址 = fields && (keyedFields.寄送地址_收件地址 as any).input.state.value;

            const 暱稱 = fields && (keyedFields.暱稱 as any).input.state.value;

            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            try {
                if (await push_pg_0_3({
                    scodeEmail,
                    vcodeEmail,
                    scodePhone,
                    vcodePhone,
                    電子郵件,
                    手機號碼,
                    手機國碼,
                    登入密碼,
                    個人介紹,
                    個人照片,
                    寄送地址_收件人,
                    寄送地址_連絡電話,
                    寄送地址_收件地址,
                    暱稱,
                })) {
                    alert('更新成功')
                    reload();
                }
            } catch (err) {
            }
        }
    }

    const _push_Pg_C_4__SendPhoneVerify = async () => {
        const 手機國碼 = fields && (keyedFields.手機國碼 as any).input.state.value;
        const 手機號碼 = fields && (keyedFields.手機號碼 as any).input.state.value;
        setPhoneVerifyStage(2);
        try {
            if (await push_pg_C_4_sendphoneverify({
                p1: `${手機國碼}${手機號碼}`,
            })) {
                let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
                setPhoneVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setPhoneVerifyStage(1);
        }
    }

    const _push_Pg_C_4__PhoneVerify = async () => {
        const 手機國碼 = fields && (keyedFields.手機國碼 as any).input.state.value;
        const 手機號碼 = fields && (keyedFields.手機號碼 as any).input.state.value;
        let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
        const vcodePhone = fields && (keyedFields.vcodePhone as any).input.state.value;

        if (!vcodePhone) {
            alert('請填入驗證碼');
            return;
        }
        if (phone !== `${手機國碼}${手機號碼}`) {
            alert('手機號碼已被修改，請重新驗證');
            setPhoneVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_C_4_phoneverify({
            p1: phone,
            scodePhone: scodePhone,
            vcodePhone: vcodePhone,
        })) {
            setPhoneVerifyStage(4);
            //navToPg_1_1();
        }
    }

    const _push_Pg_B_1__SendEmailVerify = async () => {
        const 電子郵件 = fields && (keyedFields.電子郵件 as any).input.state.value;
        setEmailVerifyStage(2);
        try {
            if (await push_pg_B_1_sendemailverify({
                p1: `${電子郵件}`,
            })) {
                let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
                setEmailVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setEmailVerifyStage(1);
        }
    }

    const _push_Pg_B_1__EmailVerify = async () => {
        const 電子郵件 = fields && (keyedFields.電子郵件 as any).input.state.value;
        let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
        const vcodeEmail = fields && (keyedFields.vcodeEmail as any).input.state.value;

        if (!vcodeEmail) {
            alert('請填入驗證碼');
            return;
        }
        if (email !== 電子郵件) {
            alert('信箱已被修改，請重新驗證');
            setEmailVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_B_1_emailverify({
            p1: email,
            scodeEmail: scodeEmail,
            vcodeEmail: vcodeEmail,
        })) {
            setEmailVerifyStage(4);
            //navToPg_1_1();
        }
    }
    //#endregion

    //#endregion

    return <>
        <div className={`${styles_Pg_0_2.avatarContainer}`}>
            <div>
                <ChunkedUploady
                    destination={{url: "/MemberUploadHandler.ashx"}}
                    chunkSize={5242880}
                    multiple={false}
                >
                    <IonAvatar className="avatar">
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: uploadedFile,
                            名稱: 'cover',
                        })}
                    </IonAvatar>
                    <div>
                        <MyUploadButton setUploadedFile={setUploadedFile}/>
                        <MyUploadProgress/>
                        <MyUploadFail/>
                    </div>
                </ChunkedUploady>


            </div>
            <div>
                <h6>{Item_會員.暱稱}</h6>
            </div>
        </div>

        <IonGrid className="ion-padding ion-margin-top ion-padding-top">
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.暱稱} errors={errors}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.電子郵件} errors={errors} disabled={emailVerifyStage === 4}/>
                </IonCol>
            </IonRow>
            {emailVerifyStage >= 1 &&
                <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                    <IonCol>
                        {emailVerifyStage === 1 &&
                            <>
                                <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__SendEmailVerify()} disabled={emailVerifyStage !== 1 ? true : false}>
                                    再次驗證電子郵件
                                </IonButton>
                            </>
                        }
                        {emailVerifyStage === 2 &&
                            <>
                                寄件中，請稍後...
                            </>
                        }
                        {emailVerifyStage === 3 &&
                            <>
                                <CustomField alternateLabel={<>
                                    <IonLabel>
                                        請檢查郵件，並填入已寄送的代碼
                                    </IonLabel>
                                </>} alternateRightZone={<>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__EmailVerify()} className={styles_Pg_C_1.sideRight}>
                                        驗證
                                    </IonButton>
                                </>
                                } field={keyedFields.vcodeEmail} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                            </>
                        }
                        {emailVerifyStage === 4 &&
                            <>
                                驗證完成
                            </>
                        }
                    </IonCol>
                </IonRow>
            }
            <IonRow>
                <IonCol>
                    <CustomField alternateLeftZone={<>
                        <IonSelect value={keyedFields.手機國碼.input.state.value} interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="+國際碼" onIonChange={(ev) => {
                            keyedFields.手機國碼.input.state.reset_WithEndCallbacks(ev.detail.value);
                        }}
                                   multiple={false} className={styles_Pg_C_1.select}>
                            {constAreaCodeList.map((v) => {
                                return <IonSelectOption key={v.countryName} value={v.phoneCode}>{v.countryName} {v.phoneCode}</IonSelectOption>
                            })}
                        </IonSelect>
                    </>} field={keyedFields.手機號碼} errors={errors} noLabel={false}/>
                </IonCol>
            </IonRow>
            {phoneVerifyStage >= 1 &&
                <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                    <IonCol>
                        {phoneVerifyStage === 1 &&
                            <>
                                <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__SendPhoneVerify()} disabled={phoneVerifyStage !== 1 ? true : false}>
                                    再次驗證手機
                                </IonButton>
                            </>
                        }
                        {phoneVerifyStage === 2 &&
                            <>
                                簡訊中，請稍後...
                            </>
                        }
                        {phoneVerifyStage === 3 &&
                            <>
                                <CustomField alternateLabel={<>
                                    <IonLabel>
                                        請檢查簡訊，並填入已寄送的代碼
                                    </IonLabel>
                                </>} alternateRightZone={<>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__PhoneVerify()} className={styles_Pg_C_1.sideRight}>
                                        驗證
                                    </IonButton>
                                </>
                                } field={keyedFields.vcodePhone} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                            </>
                        }
                        {phoneVerifyStage === 4 &&
                            <>
                                驗證完成
                            </>
                        }
                    </IonCol>
                </IonRow>
            }
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.登入密碼} errors={errors}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.個人介紹} errors={errors} textType="textarea"/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={styles_Pg_0_3.topBottomSpacing}>
                    <div className={`${styles_Pg_0_3.line} ${styles_Pg_0_3.topBottomSpacing}`}></div>
                    寄送地址:
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>

                    <CustomField field={keyedFields.寄送地址_收件人} errors={errors}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.寄送地址_連絡電話} errors={errors}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <CustomField field={keyedFields.寄送地址_收件地址} errors={errors} textType="textarea"/>
                </IonCol>
            </IonRow>


            <IonRow className="ion-margin-top ion-padding-top">
                <IonCol size="12">
                    <IonButton className="custom-button" expand="block" onClick={() => _push_pg_0_3()}>更新</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </>

}

const Pg_0_3 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_3";
    const pageId = "Pg_0_3";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();


    //#region Data Loading
    const params = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_0_3({});
            handleCommonFetchErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_0_3({});
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_3_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const Item_會員 = hasLoaded ? pgData.Root.Item_會員 : undefined;
    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <RenderForm router={router} Item_會員={Item_會員} reload={reload}/>
                }

            </CustomPage>
        </IonPage>
    );


};

export default Pg_0_3;
