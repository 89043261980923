import {IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles_TokenItem from "./TokenItem.module.scss";
import {bookmark, bookmarkOutline, heart, heartOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles_Common from "../pages/Common.module.scss";
import {push_pg_5_7__AddEntrustBookmark, push_pg_5_7__RemoveEntrustBookmark} from "../store/Pg_5_Store";
import {handleCommonFetchErrors} from "../pages/Utils";
import {push_pg_1_2__AddTokenFavor, push_pg_1_2__RemoveTokenFavor} from "../store/Pg_1_Store";
import {useStoreState} from "pullstate";
import {UserTokenEntrustStore, UserTokenFavorStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";

const _push_pg_1_2__AddTokenFavor = async (router, tokenId) => {
    let errorObj = (await push_pg_1_2__AddTokenFavor({
        p1: tokenId,
    }));
    handleCommonFetchErrors(router, errorObj);
    if (!errorObj) {
        //alert('委託成功');
        //navToPg_A_1(router);
    } else {

    }
}

const _push_pg_1_2__RemoveTokenFavor = async (router, tokenId) => {
    let errorObj = (await push_pg_1_2__RemoveTokenFavor({
        p1: tokenId,
    }));
    handleCommonFetchErrors(router, errorObj);
    if (!errorObj) {
        //alert('委託成功');
        //navToPg_A_1(router);
    } else {

    }
}

export const TokenItem = ({Item_Token, fromHome = false}) => {
    const router = useIonRouter();
    const tokenFavors = useStoreState(UserTokenFavorStore, getPageData);

    if (!Item_Token) {
        return <>(ERROR:Item_Token)</>;
    }

    // let Item_Token: any = Item_TokenEnwItem?._Token;
    // if (!Item_Token) {
    //     return <>(ERROR:Item_Token)</>;
    // }


    return (

        <ActionLink link={`/tabs/1_5/${Item_Token.TokenId}`}>
            <div className={styles_TokenItem.container}>
                <div className={styles_TokenItem.coverImg}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_Token.圖片組,
                        名稱: 'cover',
                    })}
                </div>
                {/*<div className={`${styles.stackBadge}`}>100+</div>*/}
                <div className={`${styles_TokenItem.productTitleContainer}`}>
                    <div>
                        <h1 className={`${styles_TokenItem.productTitle} truncate`}>{Item_Token.名稱}</h1>
                    </div>

                    {/*<div className="last">*/}
                    {/*    <div className="line"/>*/}
                    {/*    <div className="lowestPrice">*/}
                    {/*        /!*最低價格: {Item_Token.首次發行價格.toFixed(0)}*!/*/}
                    {/*        &nbsp;*/}
                    {/*    </div>*/}
                    {/*    <div className="right">*/}
                    {/*        <div className={styles_TokenItem.productRating}>*/}

                    {/*            {tokenFavors[Item_Token.TokenId] &&*/}
                    {/*                <>*/}
                    {/*                    <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {*/}
                    {/*                        e.preventDefault();*/}
                    {/*                        e.stopPropagation();*/}

                    {/*                        _push_pg_1_2__RemoveTokenFavor(router, Item_Token.TokenId);*/}

                    {/*                        return false;*/}
                    {/*                    }}><IonIcon icon={heart} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                    {/*                    </IonButton>*/}
                    {/*                </>*/}
                    {/*            }*/}
                    {/*            {!tokenFavors[Item_Token.TokenId] &&*/}
                    {/*                <>*/}
                    {/*                    <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {*/}
                    {/*                        e.preventDefault();*/}
                    {/*                        e.stopPropagation();*/}

                    {/*                        _push_pg_1_2__AddTokenFavor(router, Item_Token.TokenId);*/}

                    {/*                        return false;*/}
                    {/*                    }}><IonIcon icon={heartOutline} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                    {/*                    </IonButton>*/}
                    {/*                </>*/}
                    {/*            }*/}

                    {/*            /!*<IonNote>*!/*/}
                    {/*            /!*    <IonIcon icon={heartOutline}/>*!/*/}
                    {/*            /!*</IonNote>*!/*/}
                    {/*            /!*<IonNote>*!/*/}
                    {/*            /!*    {5}*!/*/}
                    {/*            /!*</IonNote>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </ActionLink>
    );
}
