import {useRef, useState} from "react";

export const useFormInput = (initialValue = "",
                             hookOnChange: any = null) => {
    const [value, setValue] = useState(initialValue);


    const reset = (newValue) => {
        setValue(newValue);
    }

    const reset_WithCallback = (newValue) => {
        setValue(newValue);

        if (hookOnChange) {
            hookOnChange(newValue, initialValue);
        }
    }
    const handleChange = async e => {
        const newValue = await e.currentTarget.value;
        setValue(newValue);

        if (hookOnChange) {
            hookOnChange(newValue, initialValue);
        }
    }

    // const handleOnKeyUp = async e => {
    //     const tempValue = await e.currentTarget.value;
    //     setValue(tempValue);
    // }

    return {
        initialValue,
        value,
        reset,
        reset_WithCallback,
        onIonChange: handleChange,
        onKeyUp: handleChange
    };
}

export const validateForm = fields => {
    let errors: any = [];

    fields.forEach(field => {
        if (field.required) {
            const fieldValue = field.input.state.value;
            if (fieldValue === "") {

                const error: any = {
                    id: field.id,
                    message: `[${field.label}] 需填寫`,
                };
                errors.push(error);
            }
        }
    });

    return errors;
}
