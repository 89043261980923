import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_18, fetch_pg_0_19, fetch_pg_0_3, Pg_0_19_Store, Pg_0_1_Store} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chevronDownCircleOutline} from "ionicons/icons";
import styles_Pg_0_2 from "./Pg_0_2.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import QRCode from "react-qr-code";
import {TokenEnwTradeItem_SellOut} from "../components/TokenEnwTradeItem_SellOut";
import {handleCommonFetchErrors, navToPg_0_18, navToPg_0_19} from "./Utils";

const Pg_0_19 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_19";
    const pageId = "Pg_0_19";
    const { sideMenuOptions } = props;
    const setSideMenu:any = useSideMenuUpdate();
    const sideMenu:any = useSideMenuOptions();

    const router = useIonRouter();

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_0_19({});
            handleCommonFetchErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_0_19({});
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_19_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const Item_會員 = hasLoaded ? pgData.Root.Item_會員 : undefined;
    const List_TokenEnw交易 = hasLoaded ? pgData.Root.List_TokenEnw交易 : undefined;
    //#endregion

    //#region +Event

    //#region click_我的委託
    const click_已購買 = async () => {
        navToPg_0_18(router);
        return false;
    }
    //#endregion

    //#region click_標記委託
    const click_已售出 = async () => {
        navToPg_0_19(router);
        return false;
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={ pageId } >
            <CustomPage name={ pageName } sideMenu={ true } sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonSegment value="已售出">
                                        <IonSegmentButton value="已購買" onClick={click_已購買}>
                                            <IonLabel>已購買</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="已售出" >
                                            <IonLabel>已售出</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            {/*<IonRow className={ styles.searchContainer }>*/}
                            {/*    <IonCol size="12">*/}
                            {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}


                            {/*click={() => handleShowModal(item)}*/}
                            {List_TokenEnw交易.map((item, index) => {
                                return <Fragment key={item.TokenEnw交易Id}>
                                    <TokenEnwTradeItem_SellOut Item_TokenEnw交易={item} noLink={true}/>
                                </Fragment>
                            })}
                        </IonGrid>
                    </>
                }

            </CustomPage>
        </IonPage>
    );
};

export default Pg_0_19;
