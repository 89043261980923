import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_18, fetch_pg_0_2, fetch_pg_0_20, fetch_pg_0_22, fetch_pg_0_3, Pg_0_1_Store, Pg_0_20_Store, Pg_0_22_Store} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chevronBack, chevronDownCircleOutline, diamondOutline} from "ionicons/icons";
import styles_Pg_0_2 from "./Pg_0_2.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import QRCode from "react-qr-code";
import styles_Pg_0_20 from "./Pg_0_20.module.scss";
import styles_Pg_0_22 from "./Pg_0_22.module.scss";
import {handleCommonFetchErrors, navToPg_0_24, navToPg_2_1} from "./Utils";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {TokenItem} from "../components/TokenItem";
import moment from "moment";
import {StoneTransactionRow} from "../components/StoneTransactionRow";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {ActionLink} from "../components/ActionLink";
import {Modal_2_3_Transfer} from "./Modal_2_3_Transfer";
import {Modal_2_3_Entrust} from "./Modal_2_3_Entrust";
import {Modal_0_24_VenderRedeem} from "./Modal_0_24_VenderRedeem";

const Pg_0_24 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_1";
    const pageId = "Pg_0_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_0_22({});
            handleCommonFetchErrors(router, errorObj);
        })();

    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_0_22({});
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_22_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const Item_合約__限定處理兌換合約 = hasLoaded ? pgData.Root.Item_合約__限定處理兌換合約 : undefined;
    //#endregion


    const start_vendorredeem = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal_trasnfer({
        //         presentingElement: pageRef.current
        //     });
        // }

        presentWebModal({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.95,
            breakpoints: [0, 0.25, 0.5, 0.75, 0.95],
        });
    }

    const [presentWebModal, dismissWebModal] = useIonModal(Modal_0_24_VenderRedeem, {
        dismiss: () => dismissWebModal(),
        //tokenEnwId: tokenEnwId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            if (result === true) {
                reload(null);
            }
        },
        //product: selectedProduct
    });

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                {hasLoaded &&
                    <>
                        {Item_合約__限定處理兌換合約 === null ?
                            <>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                            您沒有廠商權限，請先行開通
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </> : <>
                                <IonGrid className="ion-padding">
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100">
                                            <div>
                                                <ActionLink link={`/`} noLink={true}>
                                                    <IonButton fill={"solid"} onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        start_vendorredeem();

                                                        return false;
                                                    }} expand="block">
                                                        掃描兌換
                                                    </IonButton>
                                                </ActionLink>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </>}
                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_0_24;
