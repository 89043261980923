// import indexBy from "underscore/modules/indexBy";
// import {useState} from "react";
// import {useLoginFields} from "./Modal_2_8";


// export const Modal_2_1 = ({dismiss, onResult}) => {
//
//     const fields = useLoginFields();
//     let keyedFields = indexBy(fields, 'id');
//
//     const [errors, setErrors] = useState(false);
//
//     const handleScan = result => {
//         console.error(result);
//         if (result) {
//             //setQRData(result);
//             //play();
//             //handleSuccess(result);
//             alert(`代碼: ${result}`);
//             keyedFields.code.input.state.reset(result);
//             //onResult(result);
//         }
//     }
//     }
// }

// export const handleCommonThrowErrors = (router, errorObj) => {
//     if (!errorObj) {
//         console.warn(['errorObj', errorObj]);
//         return false;
//     }
//     if (errorObj.code === 2) {
//         const navToPg_A_1 = () => {
//             if (window) {
//                 ///isBrowser
//                 let pathName = window.location.pathname;
//                 let currentUrl = window.location.toString();
//
//                 if (pathName !== '/A_1') {
//                     window.location.replace('/A_1?redirTo=' + encodeURIComponent(currentUrl));
//                 } else {
//                     window.location.replace('/A_1');
//                 }
//                 //router.push("/A_1", "forward", "push");
//             }
//         }
//     }
//
//     return false;
// }
import {Logout} from "../store/Common_Store";

export const handleCommonFetchErrors = (router, errorObj) => {
    if (!errorObj) {
        console.warn(['errorObj', errorObj]);
        return false;
    }
    if (errorObj.code === 2) {
        const navToPg_A_1 = () => {
            if (window) {
                ///isBrowser
                let pathName = window.location.pathname;
                let currentUrl = window.location.toString();

                if (pathName !== '/A_1') {
                    window.location.replace('/A_1?redirTo=' + encodeURIComponent(currentUrl));
                } else {
                    window.location.replace('/A_1');
                }
                //router.push("/A_1", "forward", "push");
            }
        }
        navToPg_A_1();
        return true;
    }

    return false;
}

export const promptBrowserToLogin = () => {
    const navToPg_A_1 = () => {
        if (window) {
            ///isBrowser
            let pathName = window.location.pathname;
            let currentUrl = window.location.toString();

            if (pathName !== '/A_1') {
                window.location.assign('/A_1?redirTo=' + encodeURIComponent(currentUrl));
            } else {
                window.location.assign('/A_1');
            }
            //router.push("/A_1", "forward", "push");
        }
    }
    navToPg_A_1();
}

export const handleExecutor = async (executionQueue: string[]) => {
    if (executionQueue === null) {
        return;
    }
    for (const exec of executionQueue) {
        if (exec === "notloggin") {
            await Logout(false);
        }
    }
}


export const navToPg_1_1 = (router: any) => {
    router.push("/tabs/1_1", "forward", "push");
}
export const navToPg_1_25 = (router: any) => {
    router.push("/tabs/1_25", "forward", "push");
}

export const navToRoot = (router: any) => {
    router.push(`/`, "forward", "push");
}

export const navToPg_0_22 = (router: any) => {
    router.push("/tabs/0_22", "forward", "push");
}

export const navToPg_0_24 = (router: any) => {
    router.push("/tabs/0_24", "forward", "push");
}

export const navToPg_0_18 = (router: any) => {
    router.push("/tabs/0_18", "forward", "push");
}

export const navToPg_0_19 = (router: any) => {
    router.push("/tabs/0_19", "forward", "push");
}

export const navToPg_5_1 = (router: any) => {
    router.push("/tabs/5_1", "forward", "push");
}

export const navToPg_5_5 = (router: any) => {
    router.push("/tabs/5_5", "forward", "push");
}

export const navToPg_5_7 = (router: any) => {
    router.push("/tabs/5_7", "forward", "push");
}

export const navToPg_B_1 = (router: any) => {
    router.push("/tabs/B_1", "forward", "push");
}

export const navToPg_C_1 = (router: any) => {
    router.push("/tabs/C_1", "forward", "push");
}

export const navToPg_A_1 = (router: any) => {
    router.push("/A_1", "forward", "push");
}

export const navToPg_2_13 = (router: any) => {
    router.push("/tabs/2_13", "forward", "push");
}

export const navToPg_2_1 = (router: any) => {
    router.push("/tabs/2_1", "forward", "push");
}
export const navToPg_2_3 = (router: any, TokenEnwId: any) => {
    router.push(`/tabs/2_3/${TokenEnwId}`, "forward", "push");
}
