import React, {Component} from 'react';
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {IonImg} from "@ionic/react";

type Props = {
    圖片組?: string;

    //#region Option: Generate DefaultImage (Pick-One)
    名稱?: string;
    DefaultImage?: string;
    DefaultContent?: string;
    //#endregion

    type?: string,   //multiple, single
    testMode?: boolean,

    emitNullIfEmpty?: boolean
};

export default function Ln_ContractPictureSetCoverImg(props: Props) {
    const param_圖片組 = props['圖片組'] || '';
    const param_名稱 = props['名稱'];
    const param_DefaultImage = props['DefaultImage'];
    const param_DefaultContent = props['DefaultContent'];
    const param_TestMode = props['testMode'] || false;
    const param_type = props['type'] || 'single';

    let imgIds: any[] = _._b.getCoverSafeList(param_圖片組);  //if undefined,empty will be [null]
    let firstImgId = imgIds[0];

    if (param_TestMode) {
        return <React.Fragment>
            <IonImg src={"https://via.placeholder.com/90x90.png?text=NoImage"} alt={param_名稱}/>
        </React.Fragment>;
    }

    if (firstImgId == null) {
        if (param_DefaultImage) {
            return <React.Fragment>
                <img loading="lazy" src={param_DefaultImage}/>
            </React.Fragment>;
        }
        if (param_DefaultContent) {
            return param_DefaultContent;
        } else {
            return <React.Fragment>
                <IonImg src={"https://via.placeholder.com/700x700.png?text=NoImage"} alt={param_名稱}/>
                {/*<img src={`https://ui-avatars.com/api/?name=${encodeURIComponent(param_名稱 || '')}`}/>*/}
            </React.Fragment>;
        }
    } else {
        if (firstImgId.indexOf('data:') !== -1) {
            return <React.Fragment>
                <img loading="lazy" src={firstImgId.replaceAll('_#comma|_', ',')}/>
            </React.Fragment>;
        }

        // others
        if (param_type === "multiple") {
            return <React.Fragment>
                {imgIds.map((imgId) => {
                    return <img key={imgId} loading="lazy"
                                src={ApiService.urlSmartImageMapping("~/" + imgId, 'crop', 700, '')}
                                srcSet={`${ApiService.urlSmartImageMapping("~/" + imgId, 'crop', 700, '')} 700w, ${ApiService.urlSmartImageMapping("~/" + imgId)} 1200w`}
                    />
                })}
            </React.Fragment>;
        } else {
            return <React.Fragment>
                <img key="imgId" loading="lazy"
                     src={ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')}
                     srcSet={`${ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')} 700w, ${ApiService.urlSmartImageMapping("~/" + firstImgId)} 1200w`}
                />
            </React.Fragment>;

        }
    }
}
