import {IonBadge, IonCol, IonIcon, IonNote, IonRouterLink} from "@ionic/react";
import styles_TokenEnwItem from "./TokenEnwItem.module.scss";
import styles_TokenItem from "./TokenItem.module.scss";

import {star} from "ionicons/icons";
import Ln_ContractPictureSetCoverImg from "../pages/Ln_ContractPictureSetCoverImg";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";

export const TokenEnwItem = ({Item_TokenEnwItem, fromHome = false}) => {
    let Item_TokenEnw: any = Item_TokenEnwItem;
    let Item_Token: any = Item_TokenEnwItem?._Token;
    if (!Item_Token) {
        return <>(ERROR:Item_Token)</>;
    }

    return (
        <ActionLink link={`/tabs/2_3/${Item_TokenEnwItem.TokenEnwId}`}>
            <div className={styles_TokenItem.container}>
                <div className={styles_TokenItem.coverImg}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_Token.圖片組,
                        名稱: 'cover',
                    })}
                </div>
                {/*<div className={`${styles.stackBadge}`}>100+</div>*/}
                <div className={`${styles_TokenEnwItem.productTitleContainer}`}>
                    <div>
                        <h1 className={`${styles_TokenEnwItem.productTitle}`}>
                            <div className={`${styles_TokenEnwItem.productTitleLine} truncate`}>
                                {Item_Token.名稱}
                            </div>
                            <div className={`${styles_TokenEnwItem.productTitleLine} truncate`}>
                                {Item_TokenEnw.PetName}
                            </div>
                        </h1>
                    </div>

                    <div className="last">
                        {/*<div className="line"/>*/}
                        {/*<div className="lowestPrice">*/}
                        {/*    &nbsp;*/}
                        {/*</div>*/}
                        {/*<div className="right">*/}
                        {/*    <div className={styles.productRating}>*/}
                        {/*        <IonNote>*/}
                        {/*            <IonIcon icon={star}/>*/}
                        {/*        </IonNote>*/}
                        {/*        /!*<IonNote>*!/*/}
                        {/*        /!*    {5}*!/*/}
                        {/*        /!*</IonNote>*!/*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </ActionLink>
    );
}
