import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_1_2, fetch_pg_1_5, Pg_1_2_Store, Pg_1_5_Store} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {useEffect, useRef, useState} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline, heart, heartOutline, timeOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";

import styles_TokenItem from "../components/TokenItem.module.scss";
import styles_1_5 from './Pg_1_5.module.scss';

import moment from 'moment';
import Countdown from "react-countdown";
import {ActionLink} from "../components/ActionLink";
import {TokenEnwDetailItem} from "../components/TokenEnwDetailItem";
import {TokenDetailItem} from "../components/TokenDetailItem";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleCommonFetchErrors} from "./Utils";
import styles_2_3 from "./Pg_2_3.module.scss";

const Pg_1_5 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_1_5";
    const pageId = "Pg_1_5";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    const [hideIframe, setHideIframe] = useState(false);

    useIonViewWillEnter(() => {
        //console.log('ionViewWillEnter event fired');
        setHideIframe(false);
    });

    useIonViewWillLeave(() => {
        //console.log('ionViewWillLeave event fired');
        setHideIframe(true);
    });

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {tokenId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_1_5({
                p1: tokenId,
            });
            handleCommonFetchErrors(router, errorObj);
        })();
    }, [tokenId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_1_5({
            p1: tokenId,
        });
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_1_5_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;

    const [selectedProduct, setSelectedProduct] = useState(false);
    //#endregion


    const handleShowModal = product => {
        setSelectedProduct(product);
        present({
            cssClass: "product-modal",
            presentingElement: pageRef.current
        });
    }

    const [present, dismiss] = useIonModal(ProductModal, {
        dismiss: () => dismiss(),
        product: selectedProduct
    });

    // Renderer callback with condition
    let wasCounting = false;
    const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            if (wasCounting) {
                window.location.reload();
            }
            return null;
            //return <Completionist />;
        } else {
            // Render a countdown
            wasCounting = true;
            return <span>{days}天 {hours}時 {minutes}分 {seconds}秒</span>;
        }
    };

    let buy: any = null;


    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid className="" key={Item_Token.TokenId}>
                            {/*<IonRow className="">*/}
                            {/*    <IonCol>*/}
                            {/*        /!*<div className={styles_TokenItem.coverImg}>*!/*/}
                            {/*        /!*    {Ln_TokenPictureSetCoverImg({*!/*/}
                            {/*        /!*        圖片組: Item_Token.圖片組,*!/*/}
                            {/*        /!*        名稱: 'cover',*!/*/}
                            {/*        /!*    })}*!/*/}
                            {/*        /!*</div>*!/*/}
                            {/*        {hideIframe ||*/}
                            {/*            <iframe src={`/viewer?sm=1&url=${Item_Token.AnimationUrl}&kiosk=0`} className={styles_2_3.viewerframe}></iframe>*/}
                            {/*        }*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}
                            <IonRow>
                                <IonCol>
                                    {hideIframe ||
                                        <iframe src={`/realityviewex/?src=${Item_Token.AnimationUrl ? (window.location.origin + "/" + Item_Token.AnimationUrl) : ""}&use=${Item_Token.ArHasActions ? "" : "noactions"}&usdz=${Item_Token.ArUsdzFile ? (window.location.origin + "/" + Item_Token.ArUsdzFile) : ""}&poster=${Item_Token.ArPosterFile ?? ""}&skybox=${Item_Token.ArSkyboxFile ?? ""}&skyboxheight=${Item_Token.ArSkyboxHeight ?? ""}`} className={styles_2_3.viewerframe}></iframe>
                                    }
                                </IonCol>
                            </IonRow>
                            {/*<IonImg src={"https://via.placeholder.com/300x90.png?text=ContractImage"} alt="quote cover"/>*/}
                            {/*<IonCardContent>*/}
                            {/*</IonCardContent>*/}


                            {/*<IonRow>*/}
                            {/*    <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">*/}
                            {/*        {Item_Token.首次發行時間 == null ? <>*/}
                            {/*            尚未有首次發行時間*/}
                            {/*        </> : <>*/}
                            {/*            {moment(Item_Token.首次發行時間).toDate() <= new Date() ? <>*/}

                            {/*                {Item_Token.盲盒Id ?*/}
                            {/*                    <div>*/}
                            {/*                        <ActionLink link={`/tabs/1_6/${tokenId}`}>*/}
                            {/*                            <IonButton fill={"solid"} expand="block">*/}
                            {/*                                購買盲盒*/}
                            {/*                            </IonButton>*/}
                            {/*                        </ActionLink>*/}
                            {/*                    </div>*/}
                            {/*                    :*/}
                            {/*                    <div>*/}
                            {/*                        <ActionLink link={`/tabs/1_12/${tokenId}`}>*/}
                            {/*                            <IonButton fill={"solid"} expand="block">*/}
                            {/*                                購買*/}
                            {/*                            </IonButton>*/}
                            {/*                        </ActionLink>*/}
                            {/*                    </div>}*/}
                            {/*            </> : <>*/}
                            {/*                {moment(Item_Token.首次發行時間).diff(moment(), 'days') >= 1 ? <>*/}
                            {/*                    <h1 className={styles_1_5.timeBar}>*/}
                            {/*                        <div>*/}
                            {/*                            發行時間倒數*/}
                            {/*                        </div>*/}
                            {/*                        <div className={styles_1_5.bar}>*/}
                            {/*                            <IonIcon className="clock" icon={timeOutline}/><Countdown date={moment(Item_Token.首次發行時間).toDate()} renderer={countdownRenderer}/>*/}
                            {/*                        </div>*/}
                            {/*                    </h1>*/}
                            {/*                </> : <>*/}
                            {/*                    <h1>*/}
                            {/*                        發行時間倒數&nbsp;*/}
                            {/*                        <Countdown date={moment(Item_Token.首次發行時間).toDate()} renderer={countdownRenderer}/>*/}
                            {/*                    </h1>*/}
                            {/*                </>*/}
                            {/*                }*/}
                            {/*            </>*/}
                            {/*            }*/}
                            {/*        </>}*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}

                            <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                {/*<IonRow>*/}
                                {/*    <IonCol size="6">*/}
                                {/*        /!*<IonButton fill={ !bookmarked ? "outline" : "solid" } onClick={ () => bookmarked ? removeSavedQuote(quote.id) : addSavedQuote(quote.id) }>*!/*/}
                                {/*        /!*    <IonIcon icon={ bookmarked ? checkmarkOutline : bookmarkOutline } />*!/*/}
                                {/*        /!*    &nbsp;{ bookmarked ? "Bookmarked" : "Save as Bookmark" }*!/*/}
                                {/*        /!*</IonButton>*!/*/}
                                {/*    </IonCol>*/}

                                {/*    /!*<IonCol size="4">*!/*/}
                                {/*    /!*    <IonButton fill="outline">*!/*/}
                                {/*    /!*        <IonIcon icon={copyOutline}/>*!/*/}
                                {/*    /!*        &nbsp;Copy Quote*!/*/}
                                {/*    /!*    </IonButton>*!/*/}
                                {/*    /!*</IonCol>*!/*/}
                                {/*</IonRow>*/}

                                <IonRow>
                                    <IonCol>
                                        <h1>{Item_Token.名稱}</h1>
                                        <div dangerouslySetInnerHTML={{__html: Item_Token.介紹}}/>
                                    </IonCol>
                                </IonRow>

                                <IonGrid>
                                    {/*<IonRow className={ styles.searchContainer }>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}
                                    {/*click={() => handleShowModal(item)}*/}
                                    <TokenDetailItem Item_Token={Item_Token}/>
                                </IonGrid>
                            </IonGrid>
                        </IonGrid>
                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_1_5;
