import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import styles_Modal_2_1 from './Modal_2_1.module.scss';
import {push_pg_4_2__AddCouponBookmark} from "../store/Pg_4_Store";
import {push_pg_2_1__Redeem, push_pg_2_3__Transfer} from "../store/Pg_2_Store";
import {push_pg_5_7__AddEntrustBookmark} from "../store/Pg_5_Store";
import {handleCommonFetchErrors, navToPg_0_18} from "./Utils";
import {useStoreState} from "pullstate";
import {Pg_0_20_Store} from "../store/Pg_0_Store";
import {getPageData} from "../store/Selectors";


export const useLoginFields = () => {
    return [
        {
            id: "code",
            label: "兌換碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "輸入要兌換的代碼",
                },
                state: useFormInput("")
            }
        }

    ];
}


export const Modal_2_1 = ({dismiss, onResult}) => {
    let [mode, setMode] = useState(1);

    const router = useIonRouter();

    const _push_pg_2_1__Redeem = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            const code = fields && (keyedFields.code as any).input.state.value;
            let {errorObj, payload} = (await push_pg_2_1__Redeem({
                p1: code,
            }));
            handleCommonFetchErrors(router, errorObj);
            if (!errorObj) {
                alert(payload.SuccessMessage);  //'兌換成功'
                dismiss();

                if (onResult) {
                    onResult(true);
                }
            } else {
            }
        } else {
        }
    }

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion


    const fields = useLoginFields();
    let keyedFields = indexBy(fields, 'id');


    const [errors, setErrors] = useState(false);

    const handleScan = result => {
        if (result) {
            //setQRData(result);
            //play();
            //handleSuccess(result);
            alert(`代碼: ${result.text}`);
            //(keyedFields.code as any).input.state.setValue(result);
            keyedFields.code.input.state.reset(result.text);
            //onResult(result);
        }
    }

    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>寵物護照兌換</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                {/*<IonRow className="ion-text-center ion-margin-top" >*/}
                {/*    <IonCol size="12">*/}
                {/*        <IonIcon style={{ fontSize: "5rem" }} icon={ props.modalOptions.icon } />*/}
                {/*    </IonCol>*/}
                {/*</IonRow>*/}
                {mode === 2 &&
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonCol>
                            <IonButton fill={"solid"} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setMode(1);

                                return false;
                            }}>
                                關閉掃描
                            </IonButton>
                        </IonCol>
                    </IonRow>
                }

                {mode === 2 &&
                    <div className={styles_Modal_2_1.readerContainer}>
                        <div className={styles_Modal_2_1.reader}>
                            <QrReader
                                containerStyle={{maxHeight: '350px'}}
                                constraints={{facingMode: 'environment'}}
                                scanDelay={500}
                                videoId={'video'}
                                onResult={handleScan}
                                videoStyle={{height: "350px"}}
                            />
                        </div>
                        <div className={styles_Modal_2_1.readerBg}>
                            <div className={styles_Modal_2_1.readerBgText}>若方框內為空白畫面，表示沒有權限操作相機，請手動輸入兌換碼</div>
                        </div>
                    </div>
                }

                <IonRow className="ion-justify-content-center">
                    <IonCol>
                    </IonCol>
                </IonRow>

                {/*<IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">*/}
                {/*    <IonCol>*/}
                {/*        <IonButton fill={"solid"}>*/}
                {/*            兌換*/}
                {/*        </IonButton>*/}
                {/*    </IonCol>*/}
                {/*</IonRow>*/}

                <IonRow>
                    <IonCol>
                        <CustomField key={keyedFields.code.id} field={keyedFields.code} errors={errors} noLabel={false}/>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                    <IonCol>
                        <IonButton fill={"solid"} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            _push_pg_2_1__Redeem();
                            //alert('代碼為空，無法兌換')

                            return false;
                        }}>
                            兌換
                        </IonButton>
                    </IonCol>
                </IonRow>

                {mode === 1 &&
                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            <IonButton fill={"solid"} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setMode(2);

                                return false;
                            }}>
                                打開掃描
                            </IonButton>
                        </IonCol>
                    </IonRow>
                }
            </IonGrid>
        </IonContent>
    </>;
};
