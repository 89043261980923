import {IonAvatar, IonBadge, IonButton, IonButtons, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {Pg_C_1_Store, Pg_C_4_Store, push_pg_C_4_phoneverify, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {useEffect, useRef, useState} from "react";
import {push_pg_C_1} from "../store/Pg_C_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {personCircle} from "ionicons/icons";
import CustomField from "../components/CustomField";
import {useParams} from "react-router";
import {useFormInput, validateForm} from "../_/useForms";
import indexBy from 'underscore/modules/indexBy.js';
import {Pg_B_1_Store, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {navToPg_A_1} from "./Utils";
import {constAreaCodeList, constCatBreedList, constCityList, constCitySubList, constDogBreedList, constPetCategory} from "../_/constant";


const customSelectContainerOptions = {
    header: '手機國際碼',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

const customSelectContainerOptions_居住地 = {
    header: '居住地',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

const customSelectContainerOptions_居住區域 = {
    header: '居住區域',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

const customSelectContainerOptions_寵物類別 = {
    header: '寵物類別',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

const customSelectContainerOptions_寵物品種 = {
    header: '寵物品種',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

export const useRegisterFields = () => {
    return [
        {
            id: "p5",
            label: "我的顯示名稱",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "我的顯示名稱",
                    autocomplete: "nickname"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2",
            label: "設定登入密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "設定登入密碼",
                    autocomplete: "new-password"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2_2",
            label: "再一次輸入密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "再一次輸入密碼",
                },
                state: useFormInput("")
            }
        },
        {
            id: "p7",
            label: "您的寵物類別",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "您的寵物類別",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p8",
            label: "你的寵物名字",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "你的寵物名字",
                    autocomplete: "nickname"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p9",
            label: "寵物與您的居住地",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物與您的居住地",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p10",
            label: "寵物與您的居住區域",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物與您的居住區域",

                },
                state: useFormInput("")
            }
        },



        {
            id: "scodeEmail",
            nodisplay: true,
            label: "scodeEmail",
            required: false,
            value: null,
        },
        {
            id: "vcodeEmail",
            nodisplay: false,
            label: "vcodeEmail",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },
        {
            id: "scodePhone",
            nodisplay: true,
            label: "scodePhone",
            required: false,
            value: null,
        },
        {
            id: "vcodePhone",
            nodisplay: false,
            label: "vcodePhone",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },

        {
            id: "p1",
            label: "輸入電子郵件",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "輸入電子郵件",
                    autocomplete: "username",
                },
                state: useFormInput("")
            }
        },

        {
            id: "p3",
            label: "手機國碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機國碼",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p4",
            label: "手機號碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機號碼",
                    autocomplete: "tel-local"
                },
                state: useFormInput("")
            }
        },

        {
            id: "p6",
            label: "推薦碼",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "推薦碼 (選填)",
                },
                state: useFormInput("")
            }
        },
    ];
}


const Pg_C_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_C_1";
    const pageId = "Pg_C_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    const agreementRef = useRef<any>(null);

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Modal
    const [showModal, setShowModal] = useState<any>(false);
    const [modalOptions, setModalOptions] = useState<any>(false);

    const handleModal = async (index: any) => {
        await setModalOptions(tab1SideMenu[index]);
        setShowModal(true);
    }
    //#endregion

    //#region Data Loading
    const params = useParams();
    const [areaCode, setAreaCode] = useState('');

    const [petCategory, setPetCategory] = useState('');
    const [city, setCity] = useState('');

    const fields = useRegisterFields();
    let keyedFields = indexBy(fields, 'id');

    const [errors, setErrors] = useState(false);
    const [emailVerifyStage, setEmailVerifyStage] = useState<number>(1);
    const [phoneVerifyStage, setPhoneVerifyStage] = useState<number>(1);

    // useEffect(() => {
    //     fetch_pg_0_3({});
    // }, []);
    //
    // let reload = async (event: CustomEvent<RefresherEventDetail>) => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    //     await fetch_pg_0_3({});
    //     event.detail.complete();
    // }
    //
    // const pgData = useStoreState(Pg_0_3_Store, getPageData);
    //
    // const hasLoaded = pgData?.Root ?? false;
    // const Item_會員 = hasLoaded ? pgData.Root.Item_會員 : undefined;
    //#endregion

    //#region +Event

    //#region push register
    const push_register = async () => {
        //if (agreementRef.current.value =)

        const errors = validateForm(fields);
        setErrors(errors);

        if (errors.length > 1) {
            alert('欄位沒寫完成，請檢視 (欄位右側有註解缺甚麼)');
            return;
        }

        if (!agreementRef?.current?.checked) {
            alert('需要勾選同意會員條款');
            return;
        }

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
            //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
            //const scodePhone = fields && (keyedFields.scodePhone as any).value;
            //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;
            const p2 = fields && (keyedFields.p2 as any).input.state.value;
            const p2_2 = fields && (keyedFields.p2_2 as any).input.state.value;
            if (p2 !== p2_2) {
                alert('前後輸入的密碼不一致');
                return;
            }

            let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;
            let {phone, scodePhone, vcodePhone} = Pg_C_4_Store.getRawState() as any;

            if (emailVerifyStage !== 4) {
                alert("請先完成Email驗證流程，再按下註冊按鈕");
                return;
            }
            if (phoneVerifyStage !== 4) {
                alert("請先完成手機驗證流程，再按下註冊按鈕");
                return;
            }

            const p1 = fields && (keyedFields.p1 as any).input.state.value;
            // if (p1 !== email) {
            //     alert("填寫的Email與驗證的Email不同，請重新填寫");
            //     return;
            // }

            const p3 = fields && (keyedFields.p3 as any).input.state.value;
            const p4 = fields && (keyedFields.p4 as any).input.state.value;
            const p5 = fields && (keyedFields.p5 as any).input.state.value;
            const p6 = fields && (keyedFields.p6 as any).input.state.value;

            const p7 = fields && (keyedFields.p7 as any).input.state.value;
            const p8 = fields && (keyedFields.p8 as any).input.state.value;
            const p9 = fields && (keyedFields.p9 as any).input.state.value;
            const p10 = fields && (keyedFields.p10 as any).input.state.value;

            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            try {



                if (await push_pg_C_1({
                    scodeEmail,
                    vcodeEmail,
                    scodePhone,
                    vcodePhone,
                    p1,
                    p2,
                    p3,
                    p4,
                    p5,
                    p6,

                    p7,
                    p8,
                    p9,
                    p10,
                })) {
                    alert('謝謝註冊，現在請輸入剛註冊的帳密登入');
                    navToPg_A_1(router);
                }
            } catch (err) {

            }
        }
    }

    const _push_Pg_C_4__SendPhoneVerify = async () => {
        const p3 = fields && (keyedFields.p3 as any).input.state.value;
        const p4 = fields && (keyedFields.p4 as any).input.state.value;

        if (`${p3}` == '') {
            alert('國際碼請先選擇');
            return;
        }
        if (`${p4}` == '') {
            alert('手機號碼未填寫');
            return;
        }

        setPhoneVerifyStage(2);
        try {
            if (await push_pg_C_4_sendphoneverify({
                p1: `${p3}${p4}`,
            })) {
                let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
                setPhoneVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setPhoneVerifyStage(1);
        }
    }

    const _push_Pg_C_4__PhoneVerify = async () => {
        const p3 = fields && (keyedFields.p3 as any).input.state.value;
        const p4 = fields && (keyedFields.p4 as any).input.state.value;
        let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
        const vcodePhone = fields && (keyedFields.vcodePhone as any).input.state.value;

        if (!vcodePhone) {
            alert('請填入驗證碼');
            return;
        }
        if (phone !== `${p3}${p4}`) {
            alert('手機號碼已被修改，請重新驗證');
            setPhoneVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_C_4_phoneverify({
            p1: phone,
            scodePhone: scodePhone,
            vcodePhone: vcodePhone,
        })) {
            setPhoneVerifyStage(4);
            //navToPg_1_1();
        }
    }

    const _push_Pg_B_1__SendEmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;

        if (`${p1}` == '') {
            alert('電子郵件未填寫');
            return;
        }

        setEmailVerifyStage(2);
        try {
            if (await push_pg_B_1_sendemailverify({
                p1: `${p1}`,
            })) {
                let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
                setEmailVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setEmailVerifyStage(1);
        }
    }

    const _push_Pg_B_1__EmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;
        let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
        const vcodeEmail = fields && (keyedFields.vcodeEmail as any).input.state.value;

        if (!vcodeEmail) {
            alert('請填入驗證碼');
            return;
        }
        if (email !== p1) {
            alert('電子郵件已被修改，請重新驗證');
            setEmailVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_B_1_emailverify({
            p1: email,
            scodeEmail: scodeEmail,
            vcodeEmail: vcodeEmail,
        })) {
            setEmailVerifyStage(4);
            //navToPg_1_1();
        }
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} showLargeHeader={false} sideMenu={false} backButton={true}>
                {/*<PageHeader count={sideMenuOptions.length} pageName={pageName}/>*/}

                <IonGrid className="ion-padding ion-margin-top ion-padding-top">
                    <IonRow>
                        <IonCol>
                            輸入個人註冊資料
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p5} errors={errors}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2_2} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            輸入愛寵資訊：新註冊會員可領取一份PetJourney為您的寶貝準備的寵旅護照！
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p8} errors={errors} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {/*<CustomField field={keyedFields.p3} errors={errors}/>*/}
                            <CustomField alternateLeftZone={<>
                                <IonSelect interfaceOptions={customSelectContainerOptions_寵物類別} interface="action-sheet" placeholder="您的寵物類別" onIonChange={(ev) => {
                                    setPetCategory(JSON.stringify(ev.detail.value))
                                    keyedFields.p7.input.state.reset(ev.detail.value);
                                }}
                                           multiple={false} className={styles_Pg_C_1.select}>
                                    {constPetCategory.map((v) => {
                                        return <IonSelectOption key={v.petTypeName} value={v.value}>{v.petTypeName}</IonSelectOption>
                                    })}
                                </IonSelect>
                            </>} field={keyedFields.p7} errors={errors} isHideField={true} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {/*<CustomField field={keyedFields.p3} errors={errors}/>*/}
                            <CustomField alternateLeftZone={<>
                                <IonSelect interfaceOptions={customSelectContainerOptions_居住地} interface="action-sheet" placeholder="寵物與您的居住地" onIonChange={(ev) => {
                                    setCity(JSON.stringify(ev.detail.value))
                                    keyedFields.p9.input.state.reset(ev.detail.value);
                                }}
                                           multiple={false} className={styles_Pg_C_1.select}>
                                    {constCityList.map((v) => {
                                        return <IonSelectOption key={v.cityName} value={v.value}>{v.cityName}</IonSelectOption>
                                    })}
                                </IonSelect>
                            </>} field={keyedFields.p9} errors={errors} isHideField={true} alternateRightZone={<>
                                { keyedFields.p9.input.state.value &&
                                    <CustomField style={{flex: 1, marginLeft: 5}} alternateLeftZone={<>
                                        <IonSelect interfaceOptions={customSelectContainerOptions_居住區域} interface="action-sheet" placeholder="寵物與您的居住區域" onIonChange={(ev) => {
                                            setCity(JSON.stringify(ev.detail.value))
                                            keyedFields.p10.input.state.reset(ev.detail.value);
                                        }}
                                                   multiple={false} className={styles_Pg_C_1.select}>
                                            {constCitySubList[keyedFields.p9.input.state.value].map((v) => {
                                                return <IonSelectOption key={v.subName} value={v.value}>{v.subName}</IonSelectOption>
                                            })}
                                        </IonSelect>
                                    </>} field={keyedFields.p10} errors={errors} isHideField={true}/>
                                }
                            </>} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            驗證電子郵件
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p1} errors={errors} disabled={emailVerifyStage !== 1}/>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            {emailVerifyStage === 1 &&
                                <>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__SendEmailVerify()} disabled={emailVerifyStage !== 1}>
                                        進行驗證電子郵件
                                    </IonButton>
                                </>
                            }
                            {emailVerifyStage === 2 &&
                                <>
                                    寄件中，請稍後...
                                </>
                            }
                            {emailVerifyStage === 3 &&
                                <>
                                    <CustomField alternateLabel={<>
                                        <IonLabel>
                                            請檢查郵件，並填入驗證碼
                                        </IonLabel>
                                    </>} alternateRightZone={<>
                                        <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__EmailVerify()} className={styles_Pg_C_1.sideRight}>
                                            驗證
                                        </IonButton>
                                    </>
                                    } field={keyedFields.vcodeEmail} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                                </>
                            }
                            {emailVerifyStage === 4 &&
                                <>
                                    驗證完成
                                </>
                            }
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            驗證手機號碼
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {/*<CustomField field={keyedFields.p3} errors={errors}/>*/}
                            <CustomField alternateLeftZone={<>
                                <IonSelect style={{marginRight: 5}} interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="+國際碼" disabled={phoneVerifyStage !== 1} onIonChange={(ev) => {
                                    setAreaCode(JSON.stringify(ev.detail.value))
                                    keyedFields.p3.input.state.reset(ev.detail.value);
                                }}
                                           multiple={false} className={styles_Pg_C_1.select}>
                                    {constAreaCodeList.map((v) => {
                                        return <IonSelectOption key={v.countryName} value={v.phoneCode}>{v.countryName} {v.phoneCode}</IonSelectOption>
                                    })}
                                </IonSelect>
                            </>} field={keyedFields.p4} errors={errors} noLabel={false} />
                        </IonCol>
                    </IonRow>
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}

                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            {phoneVerifyStage === 1 &&
                                <>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__SendPhoneVerify()} disabled={phoneVerifyStage !== 1 ? true : false}>
                                        進行驗證手機
                                    </IonButton>
                                </>
                            }
                            {phoneVerifyStage === 2 &&
                                <>
                                    簡訊中，請稍後...
                                </>
                            }
                            {phoneVerifyStage === 3 &&
                                <>
                                    <CustomField alternateLabel={<>
                                        <IonLabel>
                                            請檢查簡訊，並填入驗證碼
                                        </IonLabel>
                                    </>} alternateRightZone={<>
                                        <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__PhoneVerify()} className={styles_Pg_C_1.sideRight}>
                                            驗證
                                        </IonButton>
                                    </>
                                    } field={keyedFields.vcodePhone} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                                </>
                            }
                            {phoneVerifyStage === 4 &&
                                <>
                                    驗證完成
                                </>
                            }
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p6} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            服務條款&隱私政策
                            <IonTextarea className={styles_Pg_C_1.agreement} value={
                                String.raw`
PET JOURNEY（以下簡稱「我們」）非常重視您的隱私權。當您使用我們的服務時，我們會收集和使用您的相關信息。我們希望通過本隱私政策向您說明，我們如何收集、使用、存儲和分享這些信息，以及我們為您提供的查詢、更正、刪除和保護這些信息的方式。

我們收集的信息

個人信息：
- 當您註冊賬戶時，我們會收集您的姓名、電子郵件、聯絡電話等基本信息。
- 當您使用我們服務時，我們可能會收集您的設備信息、日誌信息、位置信息等信息。

寵物信息：
- 我們會收集您提供的寵物姓名、種類、品種、年齡和照片與寵物晶片等信息。

使用信息：
- 我們會收集您在使用我們平台時的互動記錄、購買記錄、瀏覽歷史等使用信息。

我們如何使用信息

- 提供和改進服務：為您提供我們的產品和服務，並不斷改進它們。
- 個性化服務：根據您的使用情況和偏好，提供個性化的內容和建議。
- 客戶服務：向您提供客戶支持和響應您的詢問。
- 營銷和宣傳：向您推廣我們的產品、服務和活動。

信息的存儲和保護

我們將採取合理的技術和組織措施，保護您的個人信息免受未經授權的訪問、洩露、修改或毀壞。

信息的分享

我們不會與任何第三方分享您的個人信息，除非得到您的同意；法律規定；為了保護我們的合法權益。

您的權利

您有權查詢、更正或刪除我們收集的個人信息。您也可以隨時撤回對我們處理您個人信息的同意。

政策的更新

我們可能會不時更新本隱私政策。任何更新將在我們的網站上發布。


Privacy Policy

PET JOURNEY (hereinafter referred to as "we") greatly values your privacy. When you use our services, we collect and use your relevant information. Through this Privacy Policy, we aim to explain how we collect, use, store, and share this information, as well as the ways we provide for you to inquire, correct, delete, and protect this information.

Information We Collect

Personal Information:
- When you register an account, we collect your basic information such as name, email, and contact phone number.
- When you use our services, we may collect your device information, log information, location information, etc.

Pet Information:
- We collect the pet name, type, breed, age, photos and pet microchip number that you provide.

Usage Information:
- We collect interaction records, purchase records, browsing history, and other usage information when you use our platform.

How We Use Information

- Provide and Improve Services: To offer you our products and services and continuously improve them.
- Personalized Services: To provide personalized content and recommendations based on your usage and preferences.
- Customer Service: To provide customer support and respond to your inquiries.
- Marketing and Promotion: To promote our products, services, and activities to you.

Information Storage and Protection

We will take reasonable technical and organizational measures to protect your personal information from unauthorized access, disclosure, modification, or destruction.

Information Sharing

We will not share your personal information with any third party unless we have your consent; it is required by law; or to protect our legitimate rights.

Your Rights

You have the right to inquire, correct, or delete the personal information we collect. You may also withdraw your consent for us to process your personal information at any time.

Policy Updates

We may update this Privacy Policy from time to time. Any updates will be posted on our website.


服務條款

歡迎使用PET JOURNEY（以下簡稱「我們」）提供的服務。請在使用我們的服務前仔細閱讀以下服務條款。當您使用我們的服務，即表示您已閱讀、理解並同意接受本服務條款的所有規範。

服務介紹

我們提供的平台服務旨在為寵物飼主記錄和分享寵物生活點滴，並提供寵物全週期產品和服務。

用戶責任

- 真實信息： 您應保證所提供的註冊信息真實、準確、完整。
- 合法使用： 您同意以合法的方式使用我們的服務，不從事任何違法或有害的活動。
- 保護帳戶： 您有責任保護您的帳戶信息，不得將帳戶轉讓或出借給他人使用。

知識產權

我們提供的所有內容，包括但不限於文字、圖片、圖標、音頻片段、數據、軟件等，均受相關知識產權法律保護。未經我們書面許可，您不得以任何方式使用這些內容。

責任限制

我們將盡力確保服務的穩定性和可靠性，但不保證服務不會中斷或無誤。我們對因使用或無法使用服務所導致的任何直接、間接、偶然、特殊或後續損害不承擔責任。

服務變更和終止

我們保留隨時修改、暫停或終止服務的權利。對於任何服務的變更或終止，我們將提前通知用戶。

條款的修改

我們可能會不時修改本服務條款。任何修改將在我們的網站上發布。修改後的條款將自發布之日起生效。

Terms of Service

Welcome to the services provided by PET JOURNEY (hereinafter referred to as "we"). Please read the following terms of service carefully before using our services. By using our services, you acknowledge that you have read, understood, and agree to be bound by all the provisions of these terms of service.

Service Description

Our platform services aim to allow pet owners to record and share moments of their pets' lives and provide products and services for the entire pet life cycle.

User Responsibilities

- True Information: You must ensure that the registration information you provide is true, accurate, and complete.
- Legal Use: You agree to use our services in a lawful manner and not to engage in any illegal or harmful activities.
- Account Protection: You are responsible for protecting your account information and must not transfer or lend your account to others.

Intellectual Property

All content provided by us, including but not limited to text, images, icons, audio clips, data, software, etc., is protected by relevant intellectual property laws. You may not use this content in any way without our written permission.

Limitation of Liability

We will strive to ensure the stability and reliability of the services but do not guarantee that the services will be uninterrupted or error-free. We are not liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use the services.

Service Changes and Termination

We reserve the right to modify, suspend, or terminate the services at any time. We will notify users in advance of any changes or termination of services.

Terms Modification

We may modify these terms of service from time to time. Any modifications will be posted on our website and will take effect from the date of posting.

                                `
                            }>
                            </IonTextarea>

                            <IonItem>
                                <IonCheckbox slot="start" ref={agreementRef}></IonCheckbox>
                                <IonLabel>我已詳讀，並同意以上服務條款&隱私政策</IonLabel>
                            </IonItem>
                            <IonItem>
                                <div className={styles_Pg_C_1.topBottomMargin}>目前只開放中港澳台註冊，若您無法順利註冊，請以您的手機發簡訊到+886925538018，並註明（ PetJourney註冊協助：您的大名 / 您的手機 / 您的Email ），我們會幫您開通臨時帳號，並Email給您登入方式。</div>

                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">
                            <IonButton className="custom-button" expand="block" onClick={() => push_register()} >註冊</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/*{(showModal && modalOptions) &&*/}
                {/*    <Modal showModal={showModal} modalOptions={modalOptions} close={() => setShowModal(false)}/>*/}
                {/*}*/}
            </CustomPage>

            {/*<IonFooter>*/}
            {/*    <IonGrid className="ion-no-margin ion-no-padding">*/}
            {/*        <Action message="Don't have an account?" text="Sign up" link="/signup"/>*/}
            {/*        <IconWave/>*/}
            {/*    </IonGrid>*/}
            {/*</IonFooter>*/}
        </IonPage>
    );
};

export default Pg_C_1;
