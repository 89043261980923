import {Store} from "pullstate";
import _ from "../../src/_/index";
import {Pg_0_19_Store} from "./Pg_0_Store";
import indexBy from "underscore/modules/indexBy";
import {AuthStore, saveOfflineAuth, UserTokenFavorStore} from "./Common_Store";
import {Pg_6_1_Store} from "./Pg_6_Store";
import {handleExecutor} from "../pages/Utils";

export const Pg_2_1_Store = new Store({
});

export const Pg_2_13_Store = new Store({
});

export const Pg_2_3_Store = new Store({
});

export const Pg_2_4_Store = new Store({
});

export const Pg_2_3_Entrust_Store = new Store({
});

export const Pg_2_3_Transfer_Store = new Store({
});

let fetchOption = (postParams) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_pg_2_1 = async () => {
    let postParams = {
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_1_Store.update(s => { return Object.assign(s, payload) });

        await handleExecutor(payload.__Executor);
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_2_13 = async () => {
    let postParams = {
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_13`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_13_Store.update(s => { return Object.assign(s, payload) });

        if (payload.Root.Attach_List_Token_List_會員_Token__關注) {
            let keyed = indexBy(payload.Root.Attach_List_Token_List_會員_Token__關注, 'TokenId');

            UserTokenFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_2_3 = async (postParams: {
    p1: string //tokenEnwId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_3`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_3_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_2_4 = async (postParams: {
    p1: string //tokenEnwId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_4`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_4_Store.update(s => { return Object.assign(s, payload) });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const fetch_pg_2_3_entrust = async (postParams: {
    p1: string, //tokenEnwId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_3/entrust`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_3_Entrust_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_2_3_transfer = async (postParams: {
    p1: string, //tokenEnwId
    p2: string, //userId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_2_3/transfer`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_3_Transfer_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const push_pg_2_1__Buy = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_2_1/buy`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_2_1__Redeem = async (postParams: {
    p1: string,
}) => {
    if (!postParams.p1) {
        alert('p1');
    }

    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_2_1/redeem`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);

        return {errorObj: null, payload: payload};
        // Pg_2_1__Redeem_Store.update(s => {
        //     return Object.assign(s, payload)
        // });
        //return null;
    } else {
        if (error.code === 2) {
            return {errorObj: error, payload: null};
        }
        alert(error.message);
        throw (error.message);
    }

    ///TODO: network fail
}


export const push_pg_2_3__Entrust = async (postParams: {
    p1: string,
    p2: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_2_3/entrust`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_2_3_Entrust_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            return o;
        });

        return {errorObj: null, payload: payload};
    } else {
        if (error.code === 2) {
            return {errorObj: error, payload: null};
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_2_3__Transfer = async (postParams: {
    p1: string,
    p2: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_2_3/transfer`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);

        return {errorObj: null, payload: payload};
    } else {
        if (error.code === 2) {
            return {errorObj: error, payload: null};
        }
        alert(error.message);
        throw (error.message);
    }
}

export const push_pg_2_4 = async (postParams: {
    p1:string,
    PetPhoto:string,
    PetName:string,
    PetCategory:string,
    PetBreed:string,
    PetSex:string,
    PetSterillization:string,
    PetBirthday:string,
    PetChip:string,
    Edition:string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_2_4`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);

        {
            let state: any = Pg_2_4_Store.getRawState();
            if (!state?.Root?.Item_TokenEnw) {
                return false;
            }
            Pg_2_4_Store.update(s => {
                // let Item_TokenEnw = state.Root.List_圖片生成.filter(function (item) {
                //     if (item === undefined || item.圖片生成Id == postParams.p1)
                //         return false;
                //     return true;
                // });
                return Object.assign(s, {
                    Root: {
                        ...state.Root,
                        Item_TokenEnw: payload.Item_TokenEnw,
                    }
                });
            });
        }
        {
            let state: any = Pg_2_3_Store.getRawState();
            if (!state?.Root?.Item_TokenEnw) {
                return false;
            }
            Pg_2_3_Store.update(s => {
                // let Item_TokenEnw = state.Root.List_圖片生成.filter(function (item) {
                //     if (item === undefined || item.圖片生成Id == postParams.p1)
                //         return false;
                //     return true;
                // });
                return Object.assign(s, {
                    Root: {
                        ...state.Root,
                        Item_TokenEnw: payload.Item_TokenEnw,
                    }
                });
            });
        }
        return result;

        return {errorObj: null, payload: payload};
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_0_24__VenderRedeem = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_0_24/vendorredeem`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);

        return {errorObj: null, payload: payload};
    } else {
        if (error.code === 2) {
            return {errorObj: error, payload: null};
        }
        alert(error.message);
        throw (error.message);
    }
}
