import {Store} from "pullstate";
import _ from "../../src/_/index";
import indexBy from "underscore/modules/indexBy";
import {UserPostBookmarkStore, UserPostFavorStore, UserTokenEntrustStore} from "./Common_Store";
import {filter} from "ionicons/icons";
import {handleExecutor} from "../pages/Utils";

export const Pg_6_1_Store = new Store({});

export const Pg_6_2_Store = new Store({});

let fetchOption = (postParams) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_Pg_6_1 = async () => {
    let postParams = {}
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_6_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_6_1_Store.update(s => {
            return Object.assign(s, payload)
        });

        // if (payload.Root.Attach_List_會員_Token__委託關注) {
        //     let keyed = indexBy(payload.Root.Attach_List_會員_Token__委託關注, 'TokenId');
        //
        //     UserTokenEntrustStore.update(s => {
        //         return Object.assign(s, keyed)
        //     });
        // }

        await handleExecutor(payload.__Executor);
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const push_pg_6_1 = async (postParams: {}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_6_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);

        return {errorObj: null, payload: payload};
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_6_1__Delete = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_6_1/delete`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        let state: any = Pg_6_1_Store.getRawState();
        if (!state?.Root?.List_圖片生成) {
            return false;
        }
        Pg_6_1_Store.update(s => {
            let List_圖片生成 = state.Root.List_圖片生成.filter(function (item) {
                if (item === undefined || item.圖片生成Id == postParams.p1)
                    return false;
                return true;
            });
            return Object.assign(s, {
                Root : {
                    ...state.Root,
                    List_圖片生成: List_圖片生成,
                }
            });
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}
