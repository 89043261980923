import {IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_3_1, Pg_3_1_Store, push_pg_3_11__AddCommentFavor} from "../store/Pg_3_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {bookmarkOutline, chevronDownCircleOutline, copyOutline, shieldCheckmarkSharp, timeOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {Modal} from "./Modal";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import queryString from 'query-string';
import {PostItem} from "../components/PostItem";
import {ActionLink} from "../components/ActionLink";
import styles from "./Categories.module.scss";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import styles_2_1 from "./Pg_2_1.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import {push_pg_C_1, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleCommonFetchErrors, promptBrowserToLogin} from "./Utils";
import styles_4_2 from "./Pg_4_2.module.scss";
import {Modal_3_11_WriteComment} from "./Modal_3_11_WriteComment";
import {Modal_3_1_WriteArticle} from "./Modal_3_1_WriteArticle";
import styles_2_3 from "./Pg_2_3.module.scss";
import moment from "moment/moment";
import styles_1_5 from "./Pg_1_5.module.scss";
import Countdown from "react-countdown";
import {menuController} from '@ionic/core/components';
import styles_Common from "./Common.module.scss";
import {AuthStore} from "../store/Common_Store";
import {ContractBonusItem} from "../components/ContractBonusItem";

const Pg_3_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_3_1";
    const pageId = "Pg_3_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    ///#endregion

    const refIsInPage = useRef<boolean | null>(null);
    const [urlInPage, setUrlInPage] = useState<string | null>(null);

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    const {contractId = null, userId = null, isBookmark = null, isMy = null}: {
        contractId: string | null,
        userId: string | null,
        isBookmark: string | null,
        isMy: string | null,
    } = queryString.parse(window.location.search) as any;

    useIonViewWillEnter(() => {
        refIsInPage.current = true;

        if (urlInPage === null || urlInPage !== window.location.toString()) {
            setUrlInPage(window.location.toString());
        }
    });

    useIonViewWillLeave(() => {
        refIsInPage.current = false;
    });

    useEffect(() => {
        if (window.location.pathname === '/tabs/3_1') {
            if (urlInPage === null || urlInPage !== window.location.toString()) {
                setUrlInPage(window.location.toString());
            }
        }
    }, [window.location.toString()]);

    //#region Data Loading
    // useEffect(() => {
    //
    //     if (refIsInPage.current === true) {
    //         console.log('__change');
    //
    //         (async () => {
    //             const {contractId = null, userId = null, isBookmark = null, isMy = null}: {
    //                 contractId: string | null,
    //                 userId: string | null,
    //                 isBookmark: string | null,
    //                 isMy: string | null,
    //             } = queryString.parse(window.location.search) as any;
    //             let errorObj = await fetch_pg_3_1({
    //                 p1: contractId,
    //                 p2: userId,
    //                 p3: !!isBookmark,
    //                 p4: !!isMy,
    //             });
    //             handleCommonFetchErrors(router, errorObj);
    //         })();
    //
    //     }
    // }, [window.location.toString()]);

    useEffect(() => {
        if (urlInPage == null) { //skip
            return;
        }

        (async () => {
            const {contractId = null, userId = null, isBookmark = null, isMy = null}: {
                contractId: string | null,
                userId: string | null,
                isBookmark: string | null,
                isMy: string | null,
            } = queryString.parse(window.location.search) as any;
            let errorObj = await fetch_pg_3_1({
                p1: contractId,
                p2: userId,
                p3: !!isBookmark,
                p4: !!isMy,
            });
            handleCommonFetchErrors(router, errorObj);
        })();
    }, [urlInPage]);

    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        const {contractId = null, userId = null, isBookmark = null, isMy = null}: {
            contractId: string | null,
            userId: string | null,
            isBookmark: string | null,
            isMy: string | null,
        } = queryString.parse(window.location.search) as any;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_3_1({
            p1: contractId,
            p2: userId,
            p3: !!isBookmark,
            p4: !!isMy,
        });
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete?.();
    }

    // //#region Hash on changes
    // const {contractId = null, userId = null}: {
    //     contractId: string | null,
    //     userId: string | null,
    // } = queryString.parse(window.location.search) as any;
    // const pageHash = useRef<string>(`${contractId ?? ""},${userId ?? ""}`);
    // if (pageHash.current !== `${contractId ?? ""},${userId ?? ""}`) {
    //     pageHash.current = `${contractId ?? ""},${userId ?? ""}`;
    //     reload(null);
    // }
    // //#endregion

    (window as any).testreload = reload;

    const pgData = useStoreState(Pg_3_1_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_合約 = hasLoaded ? pgData.Root.List_合約 : undefined;
    const List_文章 = hasLoaded ? pgData.Root.List_文章 : undefined;
    const Item_會員公開 = hasLoaded ? pgData.Root.Item_會員公開 : undefined;
    const Item_合約 = hasLoaded ? pgData.Root.Item_合約 : undefined;
    //#endregion


    const [presentWebModal, dismissWebModal] = useIonModal(Modal_3_1_WriteArticle, {
        dismiss: () => dismissWebModal(),
        //文章Id: postId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            if (result === true) {
                reload(null);
            }
        },

        reloadParent: reload,
        //product: selectedProduct
    });

    const start = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal({
        //         presentingElement: pageRef.current
        //     });
        // }
        if (!hasAuthed) {
            promptBrowserToLogin();
            return;
        }

        presentWebModal({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.50,
            breakpoints: [0, 0.25, 0.5, 0.75],
        });
    }

    //#region +Event
    // const navToPg_1_1 = () => {
    //     router.push("/tabs/1_1", "forward", "push");
    // }

    //#region push register
    // const _push_pg_3_11__AddCommentFavor = async (commentId) => {
    //     debugger;
    //
    //     if (await push_pg_3_11__AddCommentFavor({
    //         p1: commentId,
    //     })) {
    //         //navToPg_1_1();
    //     }
    // }

    //#endregion

    //#endregion

    return (
        <IonPage id={pageId} key={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        {List_合約.length === 0 ?
                            <>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                            社群貼文暫無資料，請先前往新品商店購買，加入該社群，才能看到該社群的貼文！
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </> : <>
                                {/*<IonGrid>*/}
                                {/*    <IonRow>*/}
                                {/*        <IonCol>*/}
                                {/*            /!*<IonButton fill={ !bookmarked ? "outline" : "solid" } onClick={ () => bookmarked ? removeSavedQuote(quote.id) : addSavedQuote(quote.id) }>*!/*/}
                                {/*            /!*    <IonIcon icon={ bookmarked ? checkmarkOutline : bookmarkOutline } />*!/*/}
                                {/*            /!*    &nbsp;{ bookmarked ? "Bookmarked" : "Save as Bookmark" }*!/*/}
                                {/*            /!*</IonButton>*!/*/}
                                {/*        </IonCol>*/}

                                {/*        /!*<IonCol size="4">*!/*/}
                                {/*        /!*    <IonButton fill="outline">*!/*/}
                                {/*        /!*        <IonIcon icon={copyOutline}/>*!/*/}
                                {/*        /!*        &nbsp;Copy Quote*!/*/}
                                {/*        /!*    </IonButton>*!/*/}
                                {/*        /!*</IonCol>*!/*/}
                                {/*    </IonRow>*/}
                                {/*</IonGrid>*/}

                                {Item_會員公開 &&
                                    <div className={`${styles_2_1.avatarContainer}`}>
                                        <div>
                                            <IonAvatar className="avatar">
                                                {Ln_TokenPictureSetCoverImg({
                                                    圖片組: Item_會員公開.個人照片,
                                                    名稱: 'cover',
                                                })}
                                            </IonAvatar>
                                        </div>
                                        <div>
                                            <h6>{Item_會員公開.暱稱}{Item_會員公開.藍標 && <IonIcon icon={shieldCheckmarkSharp} className={styles_Common.userNameBlueIcon}></IonIcon>}</h6>
                                        </div>
                                    </div>
                                }

                                {/*{!Item_合約 && !Item_會員公開 &&*/}
                                {/*    <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">*/}
                                {/*        <IonRow>*/}
                                {/*            <IonCol size="12">*/}
                                {/*                <IonButton fill={"outline"}>*/}
                                {/*                    /!*<IonIcon icon={ bookmarkOutline } />*!/&nbsp;新增貼文*/}
                                {/*                </IonButton>*/}
                                {/*            </IonCol>*/}
                                {/*        </IonRow>*/}
                                {/*    </IonGrid>*/}
                                {/*}*/}

                                {contractId && Item_合約 &&
                                    <IonGrid className="padlr16px">
                                        <IonList>
                                            <IonRow>
                                                <Fragment key={Item_合約.合約Id}>
                                                    <ActionLink link={`/tabs/3_1`} noLink={true}>
                                                        現在位置: <IonChip>{Item_合約.Name}</IonChip>
                                                    </ActionLink>
                                                </Fragment>
                                            </IonRow>
                                        </IonList>
                                    </IonGrid>
                                }
                                {isBookmark &&
                                    <IonGrid className="padlr16px">
                                        <IonList>
                                            <IonRow>
                                                <ActionLink link={`/tabs/4_2`} noLink={true}>
                                                    現在位置: <IonChip className={styles_4_2.bookmarkChip}>標註的</IonChip>
                                                </ActionLink>
                                            </IonRow>
                                        </IonList>
                                    </IonGrid>
                                }
                                {isMy &&
                                    <IonGrid className="padlr16px">
                                        <IonList>
                                            <IonRow>
                                                <ActionLink link={`/tabs/4_2`} noLink={true}>
                                                    現在位置: <IonChip className={styles_4_2.bookmarkChip}>我的</IonChip>
                                                </ActionLink>
                                            </IonRow>
                                        </IonList>
                                    </IonGrid>
                                }

                                <IonGrid className="padlr16px">
                                    <IonList>
                                        <IonRow>
                                            <Fragment key={'ALL'}>
                                                <ActionLink link={`/tabs/3_1`}>
                                                    <IonChip>ALL</IonChip>
                                                </ActionLink>
                                            </Fragment>

                                            {List_合約.map(item => (
                                                // <ActionLink link={`/tabs/1_2/${item.合約Id}`} className={styles.categoryContainer} key={item.合約Id}>
                                                //     <div className={styles.categoryInner}>
                                                //         {Ln_ContractPictureSetCoverImg({
                                                //             圖片組: item.圖片組,
                                                //             名稱: 'cover',
                                                //         })}
                                                //         <div><p>{capitalize(item.Name)}</p></div>
                                                //     </div>
                                                // </ActionLink>
                                                <Fragment key={item.合約Id}>
                                                    <ActionLink link={`/tabs/3_1?contractId=${item.合約Id}`}>
                                                        <IonChip>{item.Name}</IonChip>
                                                    </ActionLink>
                                                </Fragment>
                                            ))}
                                            <Fragment key={"isBookmark"}>
                                                <ActionLink link={`/tabs/3_1?isBookmark=1`}>
                                                    <IonChip className={styles_4_2.bookmarkChip}>標註的</IonChip>
                                                </ActionLink>
                                            </Fragment>
                                            <Fragment key={"isMy"}>
                                                <ActionLink link={`/tabs/3_1?isMy=1`}>
                                                    <IonChip className={styles_4_2.bookmarkChip}>我的</IonChip>
                                                </ActionLink>
                                            </Fragment>
                                        </IonRow>
                                    </IonList>
                                </IonGrid>


                                <IonRow className="ion-justify-content-center ion-text-center w100">
                                    <IonCol>
                                        <IonButton fill={"solid"} onClick={() => start()} expand="block">
                                            新增貼文
                                        </IonButton>
                                    </IonCol>
                                </IonRow>

                                {List_文章.length === 0 ?
                                    <>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                                    目前分類，社群貼文暫無資料，請過一段時間再回來看看！
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </> : <>
                                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                            {/*<IonRow className={ styles.searchContainer }>*/}
                                            {/*    <IonCol size="12">*/}
                                            {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                            {/*    </IonCol>*/}
                                            {/*</IonRow>*/}


                                            {/*click={() => handleShowModal(item)}*/}
                                            {List_文章.map((item, index) => {
                                                return <Fragment key={item.文章Id}>
                                                    <PostItem Item_文章={item}/>
                                                </Fragment>
                                            })}
                                        </IonGrid>
                                    </>}
                            </>}
                    </>
                }

            </CustomPage>
        </IonPage>
    );
};

export default Pg_3_1;
