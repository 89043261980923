import {IonAvatar, IonBadge, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonNote, IonRouterLink, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import styles from "./TokenItem.module.scss";
import {star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import styles_1_5 from "../pages/Pg_1_5.module.scss";
import moment from "moment/moment";
import {useFormInput, validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import {Pg_B_1_Store} from "../store/Pg_B_Store";
import {Pg_C_4_Store} from "../store/Pg_C_Store";
import {push_pg_0_3} from "../store/Pg_0_Store";
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import CustomField from "./CustomField";
import styles_Pg_C_1 from "../pages/Pg_C_1.module.scss";
import {constCatBreedList, constCitySubList, constDogBreedList, constPetCategory} from "../_/constant";
import styles_PostItem from "./PostItem.module.scss";
import styles_TokenEnwDetailItem from "./TokenEnwDetailItem.module.scss";
import ChunkedUploady from "@rpldy/chunked-uploady";
import MyUploadProgress from "../pages/MyUploadProgress";
import MyUploadFail from "../pages/MyUploadFail";
import {MyUploadButton} from "../pages/Pg_0_3";

const customSelectContainerOptions_寵物類別 = {
    header: '寵物類別',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

const customSelectContainerOptions_寵物品種 = {
    header: '寵物品種',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

export const useTokenEnwDetailEditFields = (Item_TokenEnw: any) => {
    return [
        {
            id: "PetPhoto",
            label: "寵物照片",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物照片",

                },
                state: useFormInput(Item_TokenEnw.PetPhoto)
            }
        },
        {
            id: "PetName",
            label: "寵物名稱",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物名稱",
                    autocomplete: "nickname"
                },
                state: useFormInput(Item_TokenEnw.PetName)
            }
        },
        {
            id: "PetCategory",
            label: "寵物類別",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物類別",
                },
                state: useFormInput(Item_TokenEnw.PetCategory)
            }
        },
        {
            id: "PetBreed",
            label: "寵物品種",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物品種",

                },
                state: useFormInput(Item_TokenEnw.PetBreed)
            }
        },
        {
            id: "PetSex",
            label: "寵物性別",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物性別",
                },
                state: useFormInput(Item_TokenEnw.PetSex)
            }
        },
        {
            id: "PetSterillization",
            label: "絕育與否",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "絕育與否",

                },
                state: useFormInput(Item_TokenEnw.PetSterillization)
            }
        },
        {
            id: "PetBirthday",
            label: "寵物生日",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "寵物生日",
                    autocomplete: "nickname"
                },
                state: useFormInput(Item_TokenEnw.PetBirthday)
            }
        },
        {
            id: "PetChip",
            label: "晶片號碼",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "晶片號碼",
                },
                state: useFormInput(Item_TokenEnw.PetChip)
            }
        },
        {
            id: "Edition",
            label: "版數",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "版數",
                },
                state: useFormInput(Item_TokenEnw.Edition)
            }
        },
    ];
}

export const TokenEnwDetailItem = forwardRef<any, any>(({Item_TokenEnw, editMode = false}, ref) => {
    ///Ensure
    // if (!Item_TokenEnw) {
    //     return <>(ERROR:Item_TokenEnw)</>;
    // }
    // let Item_Token: any = Item_TokenEnw?._Token;
    // if (!Item_Token) {
    //     return <>(ERROR:Item_Token)</>;
    // }
    // let Item_Token_合約: any = Item_Token?._合約;
    // if (!Item_Token_合約) {
    //     return <>(ERROR:Item_Token_合約)</>;
    // }
    const [errors, setErrors] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(Item_TokenEnw.PetPhoto);


    const fields = useTokenEnwDetailEditFields(Item_TokenEnw);
    let keyedFields = indexBy(fields, 'id');

    let setUploadedFile_ = (newVal) => {
        debugger;
        keyedFields.PetPhoto.input.state.reset(newVal)
        setUploadedFile(newVal);
    }

    const gatherPostData = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            //  Submit your form here
            //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
            //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
            //const scodePhone = fields && (keyedFields.scodePhone as any).value;
            //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;

            // let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;
            // let {phone, scodePhone, vcodePhone} = Pg_C_4_Store.getRawState() as any;
            //
            // if (emailVerifyStage !== 4 && emailVerifyStage !== 0) {
            //     alert("因為電子信箱改變過，請再次先完成Email驗證流程");
            //     return;
            // }
            // if (phoneVerifyStage !== 4 && phoneVerifyStage !== 0) {
            //     alert("因為手機號改變過，請再次先完成手機驗證流程");
            //     return;
            // }
            //

            const PetPhoto = fields && (keyedFields.PetPhoto as any).input.state.value;
            const PetName = fields && (keyedFields.PetName as any).input.state.value;
            const PetCategory = fields && (keyedFields.PetCategory as any).input.state.value;
            const PetBreed = fields && (keyedFields.PetBreed as any).input.state.value;
            const PetSex = fields && (keyedFields.PetSex as any).input.state.value;
            const PetSterillization = fields && (keyedFields.PetSterillization as any).input.state.value;
            const PetBirthday = fields && (keyedFields.PetBirthday as any).input.state.value;
            const PetChip = fields && (keyedFields.PetChip as any).input.state.value;
            const Edition = fields && (keyedFields.Edition as any).input.state.value;

            // //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
            // //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
            //
            // try {
            //     if (await push_pg_0_3({
            //         PetPhoto,
            //         PetName,
            //         PetCategory,
            //         PetBreed,
            //         PetSex,
            //         PetSterillization,
            //         PetBirthday,
            //         PetChip,
            //         Edition,
            //     })) {
            //         alert('更新成功')
            //         //reload();
            //     }
            // } catch (err) {
            // }

            return {
                PetPhoto,
                PetName,
                PetCategory,
                PetBreed,
                PetSex,
                PetSterillization,
                PetBirthday,
                PetChip,
                Edition,
            }
        }
    }

    useImperativeHandle(ref, () => {
        return {
            gatherPostData,
        };
    }, [fields]);

    if (editMode) {
        return (
            <IonRow>

                <IonList className={styles_1_5.list}>
                    <IonGrid>
                        <IonRow className={styles_TokenEnwDetailItem.coverContainer}>
                            <ChunkedUploady
                                destination={{url: "/MemberUploadHandler.ashx"}}
                                chunkSize={5242880}
                                multiple={false}
                            >
                                <React.Fragment key={keyedFields.PetPhoto.input.state.value}>
                                    <div className={styles_PostItem.postBar}>
                                        {Ln_TokenPictureSetCoverImg({
                                            圖片組: keyedFields.PetPhoto.input.state.value,
                                            名稱: 'cover',
                                        })}
                                    </div>
                                    {/*<IonAvatar className="avatar">*/}
                                    {/*    {Ln_TokenPictureSetCoverImg({*/}
                                    {/*        圖片組: keyedFields.PetPhoto.input.state.value,*/}
                                    {/*        名稱: 'cover',*/}
                                    {/*    })}*/}
                                    {/*</IonAvatar>*/}
                                    <div className={"ib w100"}>
                                        <MyUploadButton setUploadedFile={setUploadedFile_}/>
                                        <MyUploadProgress/>
                                        <MyUploadFail/>
                                    </div>
                                </React.Fragment>
                            </ChunkedUploady>
                        </IonRow>
                        <div className={"dn"}>
                            <CustomField noLabelSlot={false} field={keyedFields.PetPhoto} errors={errors} disabled={true}/>
                        </div>
                    </IonGrid>

                    <IonGrid>
                        <IonRow>
                            <CustomField noLabelSlot={false} field={keyedFields.PetName} errors={errors}/>
                        </IonRow>
                    </IonGrid>


                    <IonGrid>
                        <CustomField noLabelSlot={false} alternateLeftZone={<>
                            <IonSelect interfaceOptions={customSelectContainerOptions_寵物類別} value={keyedFields.PetCategory.input.state.value} interface="action-sheet" placeholder="您的寵物類別" onIonChange={(ev) => {
                                keyedFields.PetCategory.input.state.reset(ev.detail.value);
                            }}
                                       multiple={false} className={styles_Pg_C_1.select}>
                                {constPetCategory.map((v) => {
                                    return <IonSelectOption key={v.petTypeName} value={v.value}>{v.petTypeName}</IonSelectOption>
                                })}
                            </IonSelect>
                        </>} field={keyedFields.PetCategory} errors={errors} isHideField={true} alternateRightZone={<>
                            {keyedFields.PetCategory.input.state.value === "貓" &&
                                <CustomField style={{flex: 1, marginLeft: 5}} alternateLeftZone={<>
                                    <IonSelect interfaceOptions={customSelectContainerOptions_寵物品種} value={keyedFields.PetBreed.input.state.value} interface="action-sheet" placeholder="寵物品種" onIonChange={(ev) => {
                                        keyedFields.PetBreed.input.state.reset(ev.detail.value);
                                    }}
                                               multiple={false} className={styles_Pg_C_1.select}>
                                        {constCatBreedList.map((v) => {
                                            return <IonSelectOption key={v.breedName} value={v.value}>{v.breedName}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                </>} field={keyedFields.PetBreed} errors={errors} isHideField={true}/>
                            }
                            {keyedFields.PetCategory.input.state.value === "狗" &&
                                <CustomField style={{flex: 1, marginLeft: 5}} alternateLeftZone={<>
                                    <IonSelect interfaceOptions={customSelectContainerOptions_寵物品種} value={keyedFields.PetBreed.input.state.value} interface="action-sheet" placeholder="寵物品種" onIonChange={(ev) => {
                                        keyedFields.PetBreed.input.state.reset(ev.detail.value);
                                    }}
                                               multiple={false} className={styles_Pg_C_1.select}>
                                        {constDogBreedList.map((v) => {
                                            return <IonSelectOption key={v.breedName} value={v.value}>{v.breedName}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                </>} field={keyedFields.PetBreed} errors={errors} isHideField={true}/>
                            }
                        </>}/>
                    </IonGrid>

                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <CustomField field={keyedFields.PetBreed} errors={errors}/>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    <IonGrid>
                        <IonRow>
                            <CustomField textType="radio" textTypeData={[{key: 1, value: '公', label: '公'}, {key: 1, value: '母', label: '母'}]} noLabelSlot={false} field={keyedFields.PetSex} errors={errors}/>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <CustomField textType="radio" textTypeData={[{key: 1, value: 'true', label: '是'}, {key: 1, value: 'false', label: '否'}]} noLabelSlot={false} field={keyedFields.PetSterillization} errors={errors}/>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <CustomField inputClassName={"tl dib"} textType="datePicker" noLabelSlot={false} field={keyedFields.PetBirthday} fieldType="date" errors={errors}/>
                            {/*<IonCol>{moment(Item_TokenEnw.首次發行時間).format('YYYY/MM/DD')}</IonCol>*/}
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <CustomField noLabelSlot={false} field={keyedFields.PetChip} errors={errors}/>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <CustomField noLabelSlot={false} field={keyedFields.Edition} errors={errors} disabled={true}/>
                        </IonRow>
                    </IonGrid>

                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>品牌</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token_合約.Name}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}

                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>系列</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.Series}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}

                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>名稱</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.名稱}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}

                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>版數</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_TokenEnw.Edition}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>發行數量</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.數量}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>發行數量</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.數量}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>稀有度</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.稀有度}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>發行價</IonLabel></IonCol>*/}
                    {/*            <IonCol>{Item_Token.首次發行價格}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem>*/}
                    {/*    <IonGrid>*/}
                    {/*        <IonRow>*/}
                    {/*            <IonCol><IonLabel>發行時間</IonLabel></IonCol>*/}
                    {/*            <IonCol>{moment(Item_Token.首次發行時間).format('YYYY/MM/DD hh:mm:ss A')}</IonCol>*/}
                    {/*        </IonRow>*/}
                    {/*    </IonGrid>*/}

                    {/*</IonItem>*/}
                </IonList>

                {/*<div className={styles.productContainer}>*/}
                {/*    <div className={styles.coverImage}>*/}
                {/*        {Ln_TokenPictureSetCoverImg({*/}
                {/*            圖片組: Item_Token.圖片組,*/}
                {/*            名稱: 'cover',*/}
                {/*        })}*/}
                {/*    </div>*/}
                {/*    <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>*/}

                {/*    <div className={styles.productInfo}>*/}
                {/*        <div>*/}
                {/*            <IonBadge color="primary">£{Item_Token.首次發行價格.toFixed(2)}</IonBadge>*/}
                {/*        </div>*/}

                {/*        <div className={styles.productRating}>*/}
                {/*            <IonNote>*/}
                {/*                <IonIcon icon={star}/>&nbsp;*/}
                {/*            </IonNote>*/}
                {/*            <IonNote>*/}
                {/*                {5}*/}
                {/*            </IonNote>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </IonRow>
        );
    }

    return (
        <IonRow>
            <IonList className={styles_1_5.list}>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <div className={styles_PostItem.postBar}>
                                {Ln_TokenPictureSetCoverImg({
                                    圖片組: Item_TokenEnw.PetPhoto,
                                    名稱: 'cover',
                                })}
                            </div>
                            {/*<IonCol><IonLabel>寵物照片</IonLabel></IonCol>*/}
                            {/*<IonCol>{Item_TokenEnw.PetPhoto}</IonCol>*/}
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>寵物名稱</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetName ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>寵物類別</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetCategory ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>寵物品種</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetBreed ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>寵物性別</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetSex ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>絕育與否</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetSterillization ? "是" : "否"}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>寵物生日</IonLabel></IonCol>
                            <IonCol>{moment(Item_TokenEnw.PetBirthday).isValid() ? moment(Item_TokenEnw.PetBirthday).format('YYYY/MM/DD') : '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>晶片號碼</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.PetChip ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>版數</IonLabel></IonCol>
                            <IonCol>{Item_TokenEnw.Edition ?? '-'}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>品牌</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token_合約.Name}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}

                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>系列</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.Series}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}

                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>名稱</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.名稱}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}

                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>版數</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_TokenEnw.Edition}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>發行數量</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.數量}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>發行數量</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.數量}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>稀有度</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.稀有度}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>發行價</IonLabel></IonCol>*/}
                {/*            <IonCol>{Item_Token.首次發行價格}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonGrid>*/}
                {/*        <IonRow>*/}
                {/*            <IonCol><IonLabel>發行時間</IonLabel></IonCol>*/}
                {/*            <IonCol>{moment(Item_Token.首次發行時間).format('YYYY/MM/DD hh:mm:ss A')}</IonCol>*/}
                {/*        </IonRow>*/}
                {/*    </IonGrid>*/}

                {/*</IonItem>*/}
            </IonList>

            {/*<div className={styles.productContainer}>*/}
            {/*    <div className={styles.coverImage}>*/}
            {/*        {Ln_TokenPictureSetCoverImg({*/}
            {/*            圖片組: Item_Token.圖片組,*/}
            {/*            名稱: 'cover',*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*    <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>*/}

            {/*    <div className={styles.productInfo}>*/}
            {/*        <div>*/}
            {/*            <IonBadge color="primary">£{Item_Token.首次發行價格.toFixed(2)}</IonBadge>*/}
            {/*        </div>*/}

            {/*        <div className={styles.productRating}>*/}
            {/*            <IonNote>*/}
            {/*                <IonIcon icon={star}/>&nbsp;*/}
            {/*            </IonNote>*/}
            {/*            <IonNote>*/}
            {/*                {5}*/}
            {/*            </IonNote>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </IonRow>
    );
});
