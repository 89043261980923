import {Store} from "pullstate";
import _ from "../../src/_/index";
import {UserCouponBookmarkStore, UserPostFavorStore} from "./Common_Store";
import indexBy from "underscore/modules/indexBy";
import {handleExecutor} from "../pages/Utils";

export const Pg_4_1_Store = new Store({
});

export const Pg_4_2_Store = new Store({
});

export const Pg_4_4_Store = new Store({
});


let fetchOption = (postParams) => {

    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_pg_4_2 = async (postParams: {
    p1: string | null //contractId
    p2: boolean | null //isBookmark
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_4_2`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_4_2_Store.update(s => { return Object.assign(s, payload) });

        if (payload.Root.Attach_List_合約獎勵_List_會員_合約獎勵__關注) {
            let keyed = indexBy(payload.Root.Attach_List_合約獎勵_List_會員_合約獎勵__關注, '合約獎勵Id');

            UserCouponBookmarkStore.update(s => {
                return Object.assign(s, keyed)
            });
        }

        await handleExecutor(payload.__Executor);
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const fetch_pg_4_4 = async (postParams: {
    p1: string | null //contractBonusId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_4_4`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_4_4_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}



export const push_pg_4_2__AddCouponBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_4_2/addbookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserCouponBookmarkStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}


export const push_pg_4_2__RemoveCouponBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_4_2/removebookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserCouponBookmarkStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}
