import {IonAvatar, IonBadge, IonCol, IonIcon, IonNote, IonRouterLink} from "@ionic/react";
import styles_TokenEnwEntrustListItem from "./TokenEnwEntrustListItem.module.scss";
import {shieldCheckmarkSharp, star} from "ionicons/icons";
import {ActionLink} from "./ActionLink";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import styles_Common from "../pages/Common.module.scss";

export const TokenEnwEntrustListItem = ({Item_TokenEnwEntrust, fromHome = false}) => {
    let Item_會員公開__委託者: any = Item_TokenEnwEntrust?._會員公開__委託者;
    if (!Item_會員公開__委託者) {
        return <>(ERROR:Item_會員公開__委託者)</>;
    }

    return (
        <ActionLink link={`/tabs/5_3/${Item_TokenEnwEntrust.TokenEnw委託二手交易Id}`} className={styles_TokenEnwEntrustListItem.actionlink} noLink={true}>
            <div className={styles_TokenEnwEntrustListItem.container}>
                <div className="ll">
                    <IonAvatar className="avatar">
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: Item_會員公開__委託者.個人照片,
                            名稱: 'cover',
                        })}
                    </IonAvatar>
                </div>
                <div className="l ll-1">
                    {Item_TokenEnwEntrust._會員公開__委託者.暱稱}{Item_TokenEnwEntrust._會員公開__委託者.藍標 && <IonIcon icon={shieldCheckmarkSharp} className={styles_Common.userNameBlueIcon}></IonIcon>}
                </div>
                <div className="r">
                    {Item_TokenEnwEntrust.價錢}寶石
                </div>
            </div>
        </ActionLink>
    );
}
