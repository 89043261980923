export const constPetCategory: any = [
    {petTypeName: "貓", value: "貓"},
    {petTypeName: "狗", value: "狗"},
];

export const constDogBreedList: any = [
    {breedName: "米克斯", value: "米克斯"},
    {breedName: "猴犬", value: "猴犬"},
    {breedName: "阿富汗獵狗", value: "阿富汗獵狗"},
    {breedName: "萬能㹴", value: "萬能㹴"},
    {breedName: "土耳其阿卡巴士犬", value: "土耳其阿卡巴士犬"},
    {breedName: "曾秋田犬", value: "曾秋田犬"},
    {breedName: "阿拉斯加雪撬犬", value: "阿拉斯加雪撬犬"},
    {breedName: "美國鬥牛犬", value: "美國鬥牛犬"},
    {breedName: "美國可卡犬", value: "美國可卡犬"},
    {breedName: "美國愛斯基摩犬", value: "美國愛斯基摩犬"},
    {breedName: "美國獵狐犬", value: "美國獵狐犬"},
    {breedName: "美國水獵犬", value: "美國水獵犬"},
    {breedName: "安那托利亞牧羊犬", value: "安那托利亞牧羊犬"},
    {breedName: "澳洲牧牛犬", value: "澳洲牧牛犬"},
    {breedName: "澳洲牧羊犬", value: "澳洲牧羊犬"},
    {breedName: "澳大利亞㹴", value: "澳大利亞㹴"},
    {breedName: "巴辛吉犬", value: "巴辛吉犬"},
    {breedName: "巴吉度獵犬", value: "巴吉度獵犬"},
    {breedName: "米格魯", value: "米格魯"},
    {breedName: "長鬚牧羊犬", value: "長鬚牧羊犬"},
    {breedName: "法蘭西野狼犬", value: "法蘭西野狼犬"},
    {breedName: "貝林登", value: "貝林登"},
    {breedName: "瑪利諾犬", value: "瑪利諾犬"},
    {breedName: "比利時牧羊犬", value: "比利時牧羊犬"},
    {breedName: "比利時特伏丹犬", value: "比利時特伏丹犬"},
    {breedName: "伯恩山犬", value: "伯恩山犬"},
    {breedName: "比熊犬", value: "比熊犬"},
    {breedName: "黑褐獵浣熊犬", value: "黑褐獵浣熊犬"},
    {breedName: "俄羅斯黑爹利", value: "俄羅斯黑爹利"},
    {breedName: "藍色匹卡迪檔獵犬", value: "藍色匹卡迪檔獵犬"},
    {breedName: "尋血獵犬", value: "尋血獵犬"},
    {breedName: "邊境牧羊犬", value: "邊境牧羊犬"},
    {breedName: "俄國獵狼犬", value: "俄國獵狼犬"},
    {breedName: "波士頓㹴", value: "波士頓㹴"},
    {breedName: "法蘭德斯牧羊犬", value: "法蘭德斯牧羊犬"},
    {breedName: "拳師犬", value: "拳師犬"},
    {breedName: "伯瑞犬", value: "伯瑞犬"},
    {breedName: "不列塔尼犬", value: "不列塔尼犬"},
    {breedName: "布魯塞爾格林芬犬", value: "布魯塞爾格林芬犬"},
    {breedName: "鬥牛獒", value: "鬥牛獒"},
    {breedName: "牛頭㹴", value: "牛頭㹴"},
    {breedName: "凱恩㹴", value: "凱恩㹴"},
    {breedName: "迦南犬", value: "迦南犬"},
    {breedName: "喀地干威爾斯哥基犬", value: "喀地干威爾斯哥基犬"},
    {breedName: "查理斯王騎士犬", value: "查理斯王騎士犬"},
    {breedName: "乞沙比克獵犬", value: "乞沙比克獵犬"},
    {breedName: "吉娃娃", value: "吉娃娃"},
    {breedName: "川東獵犬", value: "川東獵犬"},
    {breedName: "中國冠毛犬", value: "中國冠毛犬"},
    {breedName: "傳統中國沙皮犬", value: "傳統中國沙皮犬"},
    {breedName: "鬆獅犬", value: "鬆獅犬"},
    {breedName: "克蘭勃小獵犬", value: "克蘭勃小獵犬"},
    {breedName: "臘腸犬", value: "臘腸犬"},
    {breedName: "大麥町", value: "大麥町"},
    {breedName: "杜賓犬", value: "杜賓犬"},
    {breedName: "英國可卡犬", value: "英國可卡犬"},
    {breedName: "英國激飛獵犬", value: "英國激飛獵犬"},
    {breedName: "法國鬥牛犬", value: "法國鬥牛犬"},
    {breedName: "黃金獵犬", value: "黃金獵犬"},
    {breedName: "德國牧羊犬", value: "德國牧羊犬"},
    {breedName: "大丹犬", value: "大丹犬"},
    {breedName: "庇里牛斯山犬", value: "庇里牛斯山犬"},
    {breedName: "希臘獵犬", value: "希臘獵犬"},
    {breedName: "愛爾蘭雪達", value: "愛爾蘭雪達"},
    {breedName: "日本狆", value: "日本狆"},
    {breedName: "銀狐犬", value: "銀狐犬"},
    {breedName: "坎高犬", value: "坎高犬"},
    {breedName: "荷蘭毛獅犬", value: "荷蘭毛獅犬"},
    {breedName: "凱利藍爹利", value: "凱利藍爹利"},
    {breedName: "科克爾犬", value: "科克爾犬"},
    {breedName: "昆明犬", value: "昆明犬"},
    {breedName: "拉布拉多犬", value: "拉布拉多犬"},
    {breedName: "拉薩犬", value: "拉薩犬"},
    {breedName: "馬爾濟斯", value: "馬爾濟斯"},
    {breedName: "雪納瑞", value: "雪納瑞"},
    {breedName: "那不勒斯獒犬", value: "那不勒斯獒犬"},
    {breedName: "諾福克梗", value: "諾福克梗"},
    {breedName: "新斯科舍誘鴨尋回犬", value: "新斯科舍誘鴨尋回犬"},
    {breedName: "英國古代牧羊犬", value: "英國古代牧羊犬"},
    {breedName: "紐芬蘭犬", value: "紐芬蘭犬"},
    {breedName: "蝴蝶犬", value: "蝴蝶犬"},
    {breedName: "北京狗", value: "北京狗"},
    {breedName: "威爾斯柯基犬", value: "威爾斯柯基犬"},
    {breedName: "英國指標犬", value: "英國指標犬"},
    {breedName: "博美犬", value: "博美犬"},
    {breedName: "貴賓犬", value: "貴賓犬"},
    {breedName: "巴哥犬", value: "巴哥犬"},
    {breedName: "羅威那", value: "羅威那"},
    {breedName: "薩哈林哈士奇", value: "薩哈林哈士奇"},
    {breedName: "薩路基獵犬", value: "薩路基獵犬"},
    {breedName: "薩摩耶犬", value: "薩摩耶犬"},
    {breedName: "西里漢", value: "西里漢"},
    {breedName: "柴犬", value: "柴犬"},
    {breedName: "蘇格蘭㹴", value: "蘇格蘭㹴"},
    {breedName: "喜樂蒂牧羊犬", value: "喜樂蒂牧羊犬"},
    {breedName: "哈士奇", value: "哈士奇"},
    {breedName: "西施犬", value: "西施犬"},
    {breedName: "短毛獵狐爹利", value: "短毛獵狐爹利"},
    {breedName: "聖伯納犬", value: "聖伯納犬"},
    {breedName: "斯塔比嚎犬", value: "斯塔比嚎犬"},
    {breedName: "台灣犬", value: "台灣犬"},
    {breedName: "藏獒", value: "藏獒"},
    {breedName: "西藏獵犬", value: "西藏獵犬"},
    {breedName: "西藏㹴", value: "西藏㹴"},
    {breedName: "威瑪犬", value: "威瑪犬"},
    {breedName: "威爾斯㹴", value: "威爾斯㹴"},
    {breedName: "西高地白㹴", value: "西高地白㹴"},
    {breedName: "剛毛獵狐", value: "剛毛獵狐"},
    {breedName: "下司犬", value: "下司犬"},
    {breedName: "約克夏㹴", value: "約克夏㹴"},
    {breedName: "中國細犬", value: "中國細犬"},
    {breedName: "中國福犬", value: "中國福犬"},
    {breedName: "萊州紅犬", value: "萊州紅犬"},
];

export const constCatBreedList: any = [
    {breedName: "米克斯", value: "米克斯"},
    {breedName: "阿比西尼亞貓", value: "阿比西尼亞貓"},
    {breedName: "愛琴海貓", value: "愛琴海貓"},
    {breedName: "澳大利亞霧貓", value: "澳大利亞霧貓"},
    {breedName: "美國多趾貓", value: "美國多趾貓"},
    {breedName: "美國短尾貓", value: "美國短尾貓"},
    {breedName: "美國反耳貓", value: "美國反耳貓"},
    {breedName: "美國短毛貓", value: "美國短毛貓"},
    {breedName: "美國硬毛貓", value: "美國硬毛貓"},
    {breedName: "亞洲貓", value: "亞洲貓"},
    {breedName: "亞洲半長毛貓", value: "亞洲半長毛貓"},
    {breedName: "峇里貓", value: "峇里貓"},
    {breedName: "巴比諾貓", value: "巴比諾貓"},
    {breedName: "孟加拉貓", value: "孟加拉貓"},
    {breedName: "伯曼貓", value: "伯曼貓"},
    {breedName: "孟買貓", value: "孟買貓"},
    {breedName: "巴西短毛貓", value: "巴西短毛貓"},
    {breedName: "英國短毛貓", value: "英國短毛貓"},
    {breedName: "英國長毛貓", value: "英國長毛貓"},
    {breedName: "緬甸貓", value: "緬甸貓"},
    {breedName: "波米拉貓", value: "波米拉貓"},
    {breedName: "加州閃亮貓", value: "加州閃亮貓"},
    {breedName: "查達利貓", value: "查達利貓"},
    {breedName: "卡特爾貓", value: "卡特爾貓"},
    {breedName: "獅子貓", value: "獅子貓"},
    {breedName: "奇多貓", value: "奇多貓"},
    {breedName: "重點色短毛貓", value: "重點色短毛貓"},
    {breedName: "康沃耳帝王貓", value: "康沃耳帝王貓"},
    {breedName: "威爾斯貓", value: "威爾斯貓"},
    {breedName: "賽普勒斯短毛貓", value: "賽普勒斯短毛貓"},
    {breedName: "德文帝王貓", value: "德文帝王貓"},
    {breedName: "頓斯科伊貓", value: "頓斯科伊貓"},
    {breedName: "埃及貓", value: "埃及貓"},
    {breedName: "歐洲短毛貓", value: "歐洲短毛貓"},
    {breedName: "異國短毛貓", value: "異國短毛貓"},
    {breedName: "德國捲毛貓", value: "德國捲毛貓"},
    {breedName: "哈瓦那棕貓", value: "哈瓦那棕貓"},
    {breedName: "喜馬拉雅貓", value: "喜馬拉雅貓"},
    {breedName: "日本短尾貓", value: "日本短尾貓"},
    {breedName: "爪哇貓", value: "爪哇貓"},
    {breedName: "科拉特貓", value: "科拉特貓"},
    {breedName: "千島短尾貓", value: "千島短尾貓"},
    {breedName: "拉邦貓", value: "拉邦貓"},
    {breedName: "緬因貓", value: "緬因貓"},
    {breedName: "曼島貓", value: "曼島貓"},
    {breedName: "明斯欽貓", value: "明斯欽貓"},
    {breedName: "曼切堪貓", value: "曼切堪貓"},
    {breedName: "尼比龍貓", value: "尼比龍貓"},
    {breedName: "拿破崙貓", value: "拿破崙貓"},
    {breedName: "挪威森林貓", value: "挪威森林貓"},
    {breedName: "歐西貓", value: "歐西貓"},
    {breedName: "歐斯亞史烈斯貓", value: "歐斯亞史烈斯貓"},
    {breedName: "俄勒岡捲毛貓", value: "俄勒岡捲毛貓"},
    {breedName: "東方雙色貓", value: "東方雙色貓"},
    {breedName: "東方短毛貓", value: "東方短毛貓"},
    {breedName: "東方長毛貓", value: "東方長毛貓"},
    {breedName: "波斯貓", value: "波斯貓"},
    {breedName: "彼得禿貓", value: "彼得禿貓"},
    {breedName: "北美洲短毛貓", value: "北美洲短毛貓"},
    {breedName: "襤褸貓", value: "襤褸貓"},
    {breedName: "布偶貓", value: "布偶貓"},
    {breedName: "俄羅斯藍貓", value: "俄羅斯藍貓"},
    {breedName: "俄羅斯白貓", value: "俄羅斯白貓"},
    {breedName: "黑貓和虎斑貓", value: "黑貓和虎斑貓"},
    {breedName: "熱帶草原貓", value: "熱帶草原貓"},
    {breedName: "蘇格蘭摺耳貓", value: "蘇格蘭摺耳貓"},
    {breedName: "塞爾凱克鬈毛貓", value: "塞爾凱克鬈毛貓"},
    {breedName: "塞倫蓋蒂貓", value: "塞倫蓋蒂貓"},
    {breedName: "暹羅貓", value: "暹羅貓"},
    {breedName: "西伯利亞貓", value: "西伯利亞貓"},
    {breedName: "新加坡貓", value: "新加坡貓"},
    {breedName: "雪鞋貓", value: "雪鞋貓"},
    {breedName: "肯亞貓", value: "肯亞貓"},
    {breedName: "索馬利貓", value: "索馬利貓"},
    {breedName: "斯芬克斯貓", value: "斯芬克斯貓"},
    {breedName: "傳統暹羅貓", value: "傳統暹羅貓"},
    {breedName: "東奇尼貓", value: "東奇尼貓"},
    {breedName: "玩具虎貓", value: "玩具虎貓"},
    {breedName: "土耳其安哥拉貓", value: "土耳其安哥拉貓"},
    {breedName: "土耳其梵貓", value: "土耳其梵貓"},
    {breedName: "烏克蘭勒夫科伊貓", value: "烏克蘭勒夫科伊貓"},
    {breedName: "烏蘇里貓", value: "烏蘇里貓"},
    {breedName: "約克巧克力貓", value: "約克巧克力貓"},
    {breedName: "狸花貓", value: "狸花貓"},
];

export const constCityList: any = [
    {cityName: "基隆市", value: "基隆市"},
    {cityName: "臺北市", value: "臺北市"},
    {cityName: "新北市", value: "新北市"},
    {cityName: "桃園市", value: "桃園市"},
    {cityName: "新竹市", value: "新竹市"},
    {cityName: "新竹縣", value: "新竹縣"},
    {cityName: "苗栗縣", value: "苗栗縣"},
    {cityName: "臺中市", value: "臺中市"},
    {cityName: "彰化縣", value: "彰化縣"},
    {cityName: "南投縣", value: "南投縣"},
    {cityName: "雲林縣", value: "雲林縣"},
    {cityName: "嘉義市", value: "嘉義市"},
    {cityName: "嘉義縣", value: "嘉義縣"},
    {cityName: "臺南市", value: "臺南市"},
    {cityName: "高雄市", value: "高雄市"},
    {cityName: "屏東縣", value: "屏東縣"},
    {cityName: "臺東縣", value: "臺東縣"},
    {cityName: "花蓮縣", value: "花蓮縣"},
    {cityName: "宜蘭縣", value: "宜蘭縣"},
    {cityName: "澎湖縣", value: "澎湖縣"},
    {cityName: "金門縣", value: "金門縣"},
    {cityName: "連江縣", value: "連江縣"},
    {cityName: "其它地區", value: "其它地區"},
];



export const constCitySubList: any =
    {
        "基隆市": [
            {subName: "仁愛區", value: "仁愛區"},
            {subName: "信義區", value: "信義區"},
            {subName: "中正區", value: "中正區"},
            {subName: "中山區", value: "中山區"},
            {subName: "安樂區", value: "安樂區"},
            {subName: "暖暖區", value: "暖暖區"},
            {subName: "七堵區", value: "七堵區"},
        ]
        ,
        "臺北市": [
            {subName: "中正區", value: "中正區"},
            {subName: "大同區", value: "大同區"},
            {subName: "中山區", value: "中山區"},
            {subName: "松山區", value: "松山區"},
            {subName: "大安區", value: "大安區"},
            {subName: "萬華區", value: "萬華區"},
            {subName: "信義區", value: "信義區"},
            {subName: "士林區", value: "士林區"},
            {subName: "北投區", value: "北投區"},
            {subName: "內湖區", value: "內湖區"},
            {subName: "南港區", value: "南港區"},
            {subName: "文山區", value: "文山區"},
        ]
        ,
        "新北市": [
            {subName: "萬里區", value: "萬里區"},
            {subName: "金山區", value: "金山區"},
            {subName: "板橋區", value: "板橋區"},
            {subName: "汐止區", value: "汐止區"},
            {subName: "深坑區", value: "深坑區"},
            {subName: "石碇區", value: "石碇區"},
            {subName: "瑞芳區", value: "瑞芳區"},
            {subName: "平溪區", value: "平溪區"},
            {subName: "雙溪區", value: "雙溪區"},
            {subName: "貢寮區", value: "貢寮區"},
            {subName: "新店區", value: "新店區"},
            {subName: "坪林區", value: "坪林區"},
            {subName: "烏來區", value: "烏來區"},
            {subName: "永和區", value: "永和區"},
            {subName: "中和區", value: "中和區"},
            {subName: "土城區", value: "土城區"},
            {subName: "三峽區", value: "三峽區"},
            {subName: "樹林區", value: "樹林區"},
            {subName: "鶯歌區", value: "鶯歌區"},
            {subName: "三重區", value: "三重區"},
            {subName: "新莊區", value: "新莊區"},
            {subName: "泰山區", value: "泰山區"},
            {subName: "林口區", value: "林口區"},
            {subName: "蘆洲區", value: "蘆洲區"},
            {subName: "五股區", value: "五股區"},
            {subName: "八里區", value: "八里區"},
            {subName: "淡水區", value: "淡水區"},
            {subName: "三芝區", value: "三芝區"},
            {subName: "石門區", value: "石門區"},
        ]
        ,
        "桃園市": [
            {subName: "中壢區", value: "中壢區"},
            {subName: "平鎮區", value: "平鎮區"},
            {subName: "龍潭區", value: "龍潭區"},
            {subName: "楊梅區", value: "楊梅區"},
            {subName: "新屋區", value: "新屋區"},
            {subName: "觀音區", value: "觀音區"},
            {subName: "桃園區", value: "桃園區"},
            {subName: "龜山區", value: "龜山區"},
            {subName: "八德區", value: "八德區"},
            {subName: "大溪區", value: "大溪區"},
            {subName: "復興區", value: "復興區"},
            {subName: "大園區", value: "大園區"},
            {subName: "蘆竹區", value: "蘆竹區"},
        ]
        ,
        "新竹市": [
            {subName: "東區", value: "東區"},
            {subName: "北區", value: "北區"},
            {subName: "香山區", value: "香山區"},
        ]
        ,
        "新竹縣": [
            {subName: "竹北市", value: "竹北市"},
            {subName: "湖口鄉", value: "湖口鄉"},
            {subName: "新豐鄉", value: "新豐鄉"},
            {subName: "新埔鎮", value: "新埔鎮"},
            {subName: "關西鎮", value: "關西鎮"},
            {subName: "芎林鄉", value: "芎林鄉"},
            {subName: "寶山鄉", value: "寶山鄉"},
            {subName: "竹東鎮", value: "竹東鎮"},
            {subName: "五峰鄉", value: "五峰鄉"},
            {subName: "橫山鄉", value: "橫山鄉"},
            {subName: "尖石鄉", value: "尖石鄉"},
            {subName: "北埔鄉", value: "北埔鄉"},
            {subName: "峨眉鄉", value: "峨眉鄉"},
        ]
        ,
        "苗栗縣": [
            {subName: "竹南鎮", value: "竹南鎮"},
            {subName: "頭份市", value: "頭份市"},
            {subName: "三灣鄉", value: "三灣鄉"},
            {subName: "南庄鄉", value: "南庄鄉"},
            {subName: "獅潭鄉", value: "獅潭鄉"},
            {subName: "後龍鎮", value: "後龍鎮"},
            {subName: "通霄鎮", value: "通霄鎮"},
            {subName: "苑裡鎮", value: "苑裡鎮"},
            {subName: "苗栗市", value: "苗栗市"},
            {subName: "造橋鄉", value: "造橋鄉"},
            {subName: "頭屋鄉", value: "頭屋鄉"},
            {subName: "公館鄉", value: "公館鄉"},
            {subName: "大湖鄉", value: "大湖鄉"},
            {subName: "泰安鄉", value: "泰安鄉"},
            {subName: "銅鑼鄉", value: "銅鑼鄉"},
            {subName: "三義鄉", value: "三義鄉"},
            {subName: "西湖鄉", value: "西湖鄉"},
            {subName: "卓蘭鎮", value: "卓蘭鎮"},
        ]
        ,
        "臺中市": [
            {subName: "中區", value: "中區"},
            {subName: "東區", value: "東區"},
            {subName: "南區", value: "南區"},
            {subName: "西區", value: "西區"},
            {subName: "北區", value: "北區"},
            {subName: "北屯區", value: "北屯區"},
            {subName: "西屯區", value: "西屯區"},
            {subName: "南屯區", value: "南屯區"},
            {subName: "太平區", value: "太平區"},
            {subName: "大里區", value: "大里區"},
            {subName: "霧峰區", value: "霧峰區"},
            {subName: "烏日區", value: "烏日區"},
            {subName: "豐原區", value: "豐原區"},
            {subName: "后里區", value: "后里區"},
            {subName: "石岡區", value: "石岡區"},
            {subName: "東勢區", value: "東勢區"},
            {subName: "和平區", value: "和平區"},
            {subName: "新社區", value: "新社區"},
            {subName: "潭子區", value: "潭子區"},
            {subName: "大雅區", value: "大雅區"},
            {subName: "神岡區", value: "神岡區"},
            {subName: "大肚區", value: "大肚區"},
            {subName: "沙鹿區", value: "沙鹿區"},
            {subName: "龍井區", value: "龍井區"},
            {subName: "梧棲區", value: "梧棲區"},
            {subName: "清水區", value: "清水區"},
            {subName: "大甲區", value: "大甲區"},
            {subName: "外埔區", value: "外埔區"},
            {subName: "大安區", value: "大安區"},
        ]
        ,
        "彰化縣": [
            {subName: "彰化市", value: "彰化市"},
            {subName: "芬園鄉", value: "芬園鄉"},
            {subName: "花壇鄉", value: "花壇鄉"},
            {subName: "秀水鄉", value: "秀水鄉"},
            {subName: "鹿港鎮", value: "鹿港鎮"},
            {subName: "福興鄉", value: "福興鄉"},
            {subName: "線西鄉", value: "線西鄉"},
            {subName: "和美鎮", value: "和美鎮"},
            {subName: "伸港鄉", value: "伸港鄉"},
            {subName: "員林市", value: "員林市"},
            {subName: "社頭鄉", value: "社頭鄉"},
            {subName: "永靖鄉", value: "永靖鄉"},
            {subName: "埔心鄉", value: "埔心鄉"},
            {subName: "溪湖鎮", value: "溪湖鎮"},
            {subName: "大村鄉", value: "大村鄉"},
            {subName: "埔鹽鄉", value: "埔鹽鄉"},
            {subName: "田中鎮", value: "田中鎮"},
            {subName: "北斗鎮", value: "北斗鎮"},
            {subName: "田尾鄉", value: "田尾鄉"},
            {subName: "埤頭鄉", value: "埤頭鄉"},
            {subName: "溪州鄉", value: "溪州鄉"},
            {subName: "竹塘鄉", value: "竹塘鄉"},
            {subName: "二林鎮", value: "二林鎮"},
            {subName: "大城鄉", value: "大城鄉"},
            {subName: "芳苑鄉", value: "芳苑鄉"},
            {subName: "二水鄉", value: "二水鄉"},
        ]
        ,
        "南投縣": [
            {subName: "南投市", value: "南投市"},
            {subName: "中寮鄉", value: "中寮鄉"},
            {subName: "草屯鎮", value: "草屯鎮"},
            {subName: "國姓鄉", value: "國姓鄉"},
            {subName: "埔里鎮", value: "埔里鎮"},
            {subName: "仁愛鄉", value: "仁愛鄉"},
            {subName: "名間鄉", value: "名間鄉"},
            {subName: "集集鎮", value: "集集鎮"},
            {subName: "水里鄉", value: "水里鄉"},
            {subName: "魚池鄉", value: "魚池鄉"},
            {subName: "信義鄉", value: "信義鄉"},
            {subName: "竹山鎮", value: "竹山鎮"},
            {subName: "鹿谷鄉", value: "鹿谷鄉"},
        ]
        ,
        "雲林縣": [
            {subName: "斗南鎮", value: "斗南鎮"},
            {subName: "大埤鄉", value: "大埤鄉"},
            {subName: "虎尾鎮", value: "虎尾鎮"},
            {subName: "土庫鎮", value: "土庫鎮"},
            {subName: "褒忠鄉", value: "褒忠鄉"},
            {subName: "東勢鄉", value: "東勢鄉"},
            {subName: "臺西鄉", value: "臺西鄉"},
            {subName: "崙背鄉", value: "崙背鄉"},
            {subName: "麥寮鄉", value: "麥寮鄉"},
            {subName: "斗六市", value: "斗六市"},
            {subName: "林內鄉", value: "林內鄉"},
            {subName: "古坑鄉", value: "古坑鄉"},
            {subName: "莿桐鄉", value: "莿桐鄉"},
            {subName: "西螺鎮", value: "西螺鎮"},
            {subName: "二崙鄉", value: "二崙鄉"},
            {subName: "北港鎮", value: "北港鎮"},
            {subName: "水林鄉", value: "水林鄉"},
            {subName: "口湖鄉", value: "口湖鄉"},
            {subName: "四湖鄉", value: "四湖鄉"},
            {subName: "元長鄉", value: "元長鄉"},
        ]
        ,
        "嘉義市": [
            {subName: "東區", value: "東區"},
            {subName: "西區", value: "西區"},
        ]
        ,
        "嘉義縣": [
            {subName: "番路鄉", value: "番路鄉"},
            {subName: "梅山鄉", value: "梅山鄉"},
            {subName: "竹崎鄉", value: "竹崎鄉"},
            {subName: "阿里山鄉", value: "阿里山鄉"},
            {subName: "中埔鄉", value: "中埔鄉"},
            {subName: "大埔鄉", value: "大埔鄉"},
            {subName: "水上鄉", value: "水上鄉"},
            {subName: "鹿草鄉", value: "鹿草鄉"},
            {subName: "太保市", value: "太保市"},
            {subName: "朴子市", value: "朴子市"},
            {subName: "東石鄉", value: "東石鄉"},
            {subName: "六腳鄉", value: "六腳鄉"},
            {subName: "新港鄉", value: "新港鄉"},
            {subName: "民雄鄉", value: "民雄鄉"},
            {subName: "大林鎮", value: "大林鎮"},
            {subName: "溪口鄉", value: "溪口鄉"},
            {subName: "義竹鄉", value: "義竹鄉"},
            {subName: "布袋鎮", value: "布袋鎮"},
        ]
        ,
        "臺南市": [
            {subName: "中西區", value: "中西區"},
            {subName: "東區", value: "東區"},
            {subName: "南區", value: "南區"},
            {subName: "北區", value: "北區"},
            {subName: "安平區", value: "安平區"},
            {subName: "安南區", value: "安南區"},
            {subName: "永康區", value: "永康區"},
            {subName: "歸仁區", value: "歸仁區"},
            {subName: "新化區", value: "新化區"},
            {subName: "左鎮區", value: "左鎮區"},
            {subName: "玉井區", value: "玉井區"},
            {subName: "楠西區", value: "楠西區"},
            {subName: "南化區", value: "南化區"},
            {subName: "仁德區", value: "仁德區"},
            {subName: "關廟區", value: "關廟區"},
            {subName: "龍崎區", value: "龍崎區"},
            {subName: "官田區", value: "官田區"},
            {subName: "麻豆區", value: "麻豆區"},
            {subName: "佳里區", value: "佳里區"},
            {subName: "西港區", value: "西港區"},
            {subName: "七股區", value: "七股區"},
            {subName: "將軍區", value: "將軍區"},
            {subName: "學甲區", value: "學甲區"},
            {subName: "北門區", value: "北門區"},
            {subName: "新營區", value: "新營區"},
            {subName: "後壁區", value: "後壁區"},
            {subName: "白河區", value: "白河區"},
            {subName: "東山區", value: "東山區"},
            {subName: "六甲區", value: "六甲區"},
            {subName: "下營區", value: "下營區"},
            {subName: "柳營區", value: "柳營區"},
            {subName: "鹽水區", value: "鹽水區"},
            {subName: "善化區", value: "善化區"},
            {subName: "大內區", value: "大內區"},
            {subName: "山上區", value: "山上區"},
            {subName: "新市區", value: "新市區"},
            {subName: "安定區", value: "安定區"},
        ]
        ,
        "高雄市": [
            {subName: "新興區", value: "新興區"},
            {subName: "前金區", value: "前金區"},
            {subName: "苓雅區", value: "苓雅區"},
            {subName: "鹽埕區", value: "鹽埕區"},
            {subName: "鼓山區", value: "鼓山區"},
            {subName: "旗津區", value: "旗津區"},
            {subName: "前鎮區", value: "前鎮區"},
            {subName: "三民區", value: "三民區"},
            {subName: "楠梓區", value: "楠梓區"},
            {subName: "小港區", value: "小港區"},
            {subName: "左營區", value: "左營區"},
            {subName: "仁武區", value: "仁武區"},
            {subName: "大社區", value: "大社區"},
            {subName: "東沙群島", value: "東沙群島"},
            {subName: "南沙群島", value: "南沙群島"},
            {subName: "岡山區", value: "岡山區"},
            {subName: "路竹區", value: "路竹區"},
            {subName: "阿蓮區", value: "阿蓮區"},
            {subName: "田寮區", value: "田寮區"},
            {subName: "燕巢區", value: "燕巢區"},
            {subName: "橋頭區", value: "橋頭區"},
            {subName: "梓官區", value: "梓官區"},
            {subName: "彌陀區", value: "彌陀區"},
            {subName: "永安區", value: "永安區"},
            {subName: "湖內區", value: "湖內區"},
            {subName: "鳳山區", value: "鳳山區"},
            {subName: "大寮區", value: "大寮區"},
            {subName: "林園區", value: "林園區"},
            {subName: "鳥松區", value: "鳥松區"},
            {subName: "大樹區", value: "大樹區"},
            {subName: "旗山區", value: "旗山區"},
            {subName: "美濃區", value: "美濃區"},
            {subName: "六龜區", value: "六龜區"},
            {subName: "內門區", value: "內門區"},
            {subName: "杉林區", value: "杉林區"},
            {subName: "甲仙區", value: "甲仙區"},
            {subName: "桃源區", value: "桃源區"},
            {subName: "那瑪夏區", value: "那瑪夏區"},
            {subName: "茂林區", value: "茂林區"},
            {subName: "茄萣區", value: "茄萣區"},
        ]
        ,
        "屏東縣": [
            {subName: "屏東市", value: "屏東市"},
            {subName: "三地門鄉", value: "三地門鄉"},
            {subName: "霧臺鄉", value: "霧臺鄉"},
            {subName: "瑪家鄉", value: "瑪家鄉"},
            {subName: "九如鄉", value: "九如鄉"},
            {subName: "里港鄉", value: "里港鄉"},
            {subName: "高樹鄉", value: "高樹鄉"},
            {subName: "鹽埔鄉", value: "鹽埔鄉"},
            {subName: "長治鄉", value: "長治鄉"},
            {subName: "麟洛鄉", value: "麟洛鄉"},
            {subName: "竹田鄉", value: "竹田鄉"},
            {subName: "內埔鄉", value: "內埔鄉"},
            {subName: "萬丹鄉", value: "萬丹鄉"},
            {subName: "潮州鎮", value: "潮州鎮"},
            {subName: "泰武鄉", value: "泰武鄉"},
            {subName: "來義鄉", value: "來義鄉"},
            {subName: "萬巒鄉", value: "萬巒鄉"},
            {subName: "崁頂鄉", value: "崁頂鄉"},
            {subName: "新埤鄉", value: "新埤鄉"},
            {subName: "南州鄉", value: "南州鄉"},
            {subName: "林邊鄉", value: "林邊鄉"},
            {subName: "東港鎮", value: "東港鎮"},
            {subName: "琉球鄉", value: "琉球鄉"},
            {subName: "佳冬鄉", value: "佳冬鄉"},
            {subName: "新園鄉", value: "新園鄉"},
            {subName: "枋寮鄉", value: "枋寮鄉"},
            {subName: "枋山鄉", value: "枋山鄉"},
            {subName: "春日鄉", value: "春日鄉"},
            {subName: "獅子鄉", value: "獅子鄉"},
            {subName: "車城鄉", value: "車城鄉"},
            {subName: "牡丹鄉", value: "牡丹鄉"},
            {subName: "恆春鎮", value: "恆春鎮"},
            {subName: "滿州鄉", value: "滿州鄉"},
        ]
        ,
        "臺東縣": [
            {subName: "臺東市", value: "臺東市"},
            {subName: "綠島鄉", value: "綠島鄉"},
            {subName: "蘭嶼鄉", value: "蘭嶼鄉"},
            {subName: "延平鄉", value: "延平鄉"},
            {subName: "卑南鄉", value: "卑南鄉"},
            {subName: "鹿野鄉", value: "鹿野鄉"},
            {subName: "關山鎮", value: "關山鎮"},
            {subName: "海端鄉", value: "海端鄉"},
            {subName: "池上鄉", value: "池上鄉"},
            {subName: "東河鄉", value: "東河鄉"},
            {subName: "成功鎮", value: "成功鎮"},
            {subName: "長濱鄉", value: "長濱鄉"},
            {subName: "太麻里鄉", value: "太麻里鄉"},
            {subName: "金峰鄉", value: "金峰鄉"},
            {subName: "大武鄉", value: "大武鄉"},
            {subName: "達仁鄉", value: "達仁鄉"},
        ]
        ,
        "花蓮縣": [
            {subName: "花蓮市", value: "花蓮市"},
            {subName: "新城鄉", value: "新城鄉"},
            {subName: "秀林鄉", value: "秀林鄉"},
            {subName: "吉安鄉", value: "吉安鄉"},
            {subName: "壽豐鄉", value: "壽豐鄉"},
            {subName: "鳳林鎮", value: "鳳林鎮"},
            {subName: "光復鄉", value: "光復鄉"},
            {subName: "豐濱鄉", value: "豐濱鄉"},
            {subName: "瑞穗鄉", value: "瑞穗鄉"},
            {subName: "萬榮鄉", value: "萬榮鄉"},
            {subName: "玉里鎮", value: "玉里鎮"},
            {subName: "卓溪鄉", value: "卓溪鄉"},
            {subName: "富里鄉", value: "富里鄉"},
        ]
        ,
        "宜蘭縣": [
            {subName: "宜蘭市", value: "宜蘭市"},
            {subName: "頭城鎮", value: "頭城鎮"},
            {subName: "礁溪鄉", value: "礁溪鄉"},
            {subName: "壯圍鄉", value: "壯圍鄉"},
            {subName: "員山鄉", value: "員山鄉"},
            {subName: "羅東鎮", value: "羅東鎮"},
            {subName: "三星鄉", value: "三星鄉"},
            {subName: "大同鄉", value: "大同鄉"},
            {subName: "五結鄉", value: "五結鄉"},
            {subName: "冬山鄉", value: "冬山鄉"},
            {subName: "蘇澳鎮", value: "蘇澳鎮"},
            {subName: "南澳鄉", value: "南澳鄉"},
            {subName: "釣魚臺", value: "釣魚臺"},
        ]
        ,
        "澎湖縣": [
            {subName: "馬公市", value: "馬公市"},
            {subName: "西嶼鄉", value: "西嶼鄉"},
            {subName: "望安鄉", value: "望安鄉"},
            {subName: "七美鄉", value: "七美鄉"},
            {subName: "白沙鄉", value: "白沙鄉"},
            {subName: "湖西鄉", value: "湖西鄉"},
        ]
        ,
        "金門縣": [
            {subName: "金沙鎮", value: "金沙鎮"},
            {subName: "金湖鎮", value: "金湖鎮"},
            {subName: "金寧鄉", value: "金寧鄉"},
            {subName: "金城鎮", value: "金城鎮"},
            {subName: "烈嶼鄉", value: "烈嶼鄉"},
            {subName: "烏坵鄉", value: "烏坵鄉"},
        ]
        ,
        "連江縣": [
            {subName: "南竿鄉", value: "南竿鄉"},
            {subName: "北竿鄉", value: "北竿鄉"},
            {subName: "莒光鄉", value: "莒光鄉"},
            {subName: "東引鄉", value: "東引鄉"},
        ],
        "其它地區": [
            {subName: "香港", value: "香港"},
            {subName: "澳門", value: "澳門"},
            {subName: "中國", value: "中國"},
            {subName: "美國", value: "美國"},
        ]
    };

export const constAreaCodeList: any = [
    {
        "countryCode": "TW",
        "countryName": "台灣",
        "phoneCode": "+886"
    },
    {
        "countryCode": "CN",
        "countryName": "中國",
        "phoneCode": "+86"
    },
    {
        "countryCode": "HK",
        "countryName": "香港",
        "phoneCode": "+852"
    },
    {
        "countryCode": "MO",
        "countryName": "澳門",
        "phoneCode": "+853"
    },
    // {
    //     "countryCode": "US",
    //     "countryName": "美國",
    //     "phoneCode": "+1"
    // },
    // {
    //     "countryCode": "CA",
    //     "countryName": "加拿大",
    //     "phoneCode": "+1"
    // },
    // {
    //     "countryCode": "UK",
    //     "countryName": "英國",
    //     "phoneCode": "+44"
    // },
    // {
    //     "countryCode": "SG",
    //     "countryName": "新加坡",
    //     "phoneCode": "+65"
    // },
    // {
    //     "countryCode": "NZ",
    //     "countryName": "紐西蘭",
    //     "phoneCode": "+64"
    // },
    // {
    //     "countryCode": "JP",
    //     "countryName": "日本",
    //     "phoneCode": "+81"
    // },
    // {
    //     "countryCode": "KP",
    //     "countryName": "韓國",
    //     "phoneCode": "+82"
    // },
    // {
    //     "countryCode": "AU",
    //     "countryName": "澳大利亞",
    //     "phoneCode": "+61"
    // },
    // {
    //     "countryCode": "FR",
    //     "countryName": "法國",
    //     "phoneCode": "+33"
    // },
    // {
    //     "countryCode": "DE",
    //     "countryName": "德國",
    //     "phoneCode": "+49"
    // },


    // {
    //     "countryCode": "AF",
    //     "countryName": "阿富汗",
    //     "phoneCode": "+93"
    // },
    // {
    //     "countryCode": "AR",
    //     "countryName": "阿根廷",
    //     "phoneCode": "+54"
    // },
    // {
    //     "countryCode": "AT",
    //     "countryName": "奧地利",
    //     "phoneCode": "+43"
    // },
    //
    // {
    //     "countryCode": "BH",
    //     "countryName": "巴林",
    //     "phoneCode": "+973"
    // },
    // {
    //     "countryCode": "BD",
    //     "countryName": "孟加拉",
    //     "phoneCode": "+880"
    // },
    // {
    //     "countryCode": "BE",
    //     "countryName": "比利時",
    //     "phoneCode": "+32"
    // },
    // {
    //     "countryCode": "BT",
    //     "countryName": "不丹",
    //     "phoneCode": "+975"
    // },
    // {
    //     "countryCode": "BO",
    //     "countryName": "玻利維亞",
    //     "phoneCode": "+591"
    // },
    // {
    //     "countryCode": "BR",
    //     "countryName": "巴西",
    //     "phoneCode": "+55"
    // },
    // {
    //     "countryCode": "KH",
    //     "countryName": "柬埔寨",
    //     "phoneCode": "+855"
    // },
    // {
    //     "countryCode": "CM",
    //     "countryName": "喀麥隆",
    //     "phoneCode": "+237"
    // },
    // {
    //     "countryCode": "AI",
    //     "countryName": "安圭拉",
    //     "phoneCode": "+1264"
    // },
    // {
    //     "countryCode": "AG",
    //     "countryName": "安地瓜",
    //     "phoneCode": "+1268"
    // },
    // {
    //     "countryCode": "AW",
    //     "countryName": "阿魯巴",
    //     "phoneCode": "+297"
    // },
    // {
    //     "countryCode": "BM",
    //     "countryName": "百慕達",
    //     "phoneCode": "+1441"
    // },
    // {
    //     "countryCode": "DO",
    //     "countryName": "多明尼加",
    //     "phoneCode": "+1767"
    // },
    // {
    //     "countryCode": "GD",
    //     "countryName": "格瑞那達",
    //     "phoneCode": "+1473"
    // },
    // {
    //     "countryCode": "LC",
    //     "countryName": "聖盧西亞",
    //     "phoneCode": "+1758"
    // },
    // {
    //     "countryCode": "CO",
    //     "countryName": "哥倫比亞",
    //     "phoneCode": "+57"
    // },
    // {
    //     "countryCode": "CG",
    //     "countryName": "剛果共和國",
    //     "phoneCode": "+243"
    // },
    // {
    //     "countryCode": "CH",
    //     "countryName": "瑞士",
    //     "phoneCode": "+41"
    // },
    //
    // {
    //     "countryCode": "DK",
    //     "countryName": "丹麥",
    //     "phoneCode": "+45"
    // },
    // {
    //     "countryCode": "EG",
    //     "countryName": "埃及",
    //     "phoneCode": "+20"
    // },
    // {
    //     "countryCode": "ES",
    //     "countryName": "西班牙",
    //     "phoneCode": "+34"
    // },
    // {
    //     "countryCode": "SV",
    //     "countryName": "薩爾瓦多",
    //     "phoneCode": "+503"
    // },
    // {
    //     "countryCode": "FI",
    //     "countryName": "芬蘭",
    //     "phoneCode": "+358"
    // },
    // {
    //     "countryCode": "FJ",
    //     "countryName": "斐濟",
    //     "phoneCode": "+679"
    // },
    //
    // {
    //     "countryCode": "GE",
    //     "countryName": "喬治亞",
    //     "phoneCode": "+995"
    // },
    // {
    //     "countryCode": "GH",
    //     "countryName": "迦納",
    //     "phoneCode": "+233"
    // },
    // {
    //     "countryCode": "GR",
    //     "countryName": "希臘",
    //     "phoneCode": "+30"
    // },
    // {
    //     "countryCode": "GT",
    //     "countryName": "瓜地馬拉",
    //     "phoneCode": "+502"
    // },
    // {
    //     "countryCode": "GY",
    //     "countryName": "蓋亞那",
    //     "phoneCode": "+967"
    // },
    // {
    //     "countryCode": "HT",
    //     "countryName": "海地",
    //     "phoneCode": "+509"
    // },
    // {
    //     "countryCode": "HN",
    //     "countryName": "宏都拉斯",
    //     "phoneCode": "+504"
    // },
    //
    // {
    //     "countryCode": "IN",
    //     "countryName": "印度",
    //     "phoneCode": "+91"
    // },
    // {
    //     "countryCode": "IS",
    //     "countryName": "冰島",
    //     "phoneCode": "+354"
    // },
    // {
    //     "countryCode": "ID",
    //     "countryName": "印尼",
    //     "phoneCode": "+62"
    // },
    // {
    //     "countryCode": "IQ",
    //     "countryName": "伊拉克",
    //     "phoneCode": "+964"
    // },
    // {
    //     "countryCode": "IE",
    //     "countryName": "愛爾蘭",
    //     "phoneCode": "+353"
    // },
    // {
    //     "countryCode": "IT",
    //     "countryName": "義大利",
    //     "phoneCode": "+39"
    // },
    // {
    //     "countryCode": "JM",
    //     "countryName": "牙買加",
    //     "phoneCode": "+1876"
    // },
    // {
    //     "countryCode": "JO",
    //     "countryName": "約旦",
    //     "phoneCode": "+962"
    // },
    // {
    //     "countryCode": "KZ",
    //     "countryName": "哈薩克",
    //     "phoneCode": "+7"
    // },
    // {
    //     "countryCode": "KE",
    //     "countryName": "肯亞",
    //     "phoneCode": "+254"
    // },
    //
    // {
    //     "countryCode": "KW",
    //     "countryName": "科威特",
    //     "phoneCode": "+965"
    // },
    // {
    //     "countryCode": "LU",
    //     "countryName": "盧森堡",
    //     "phoneCode": "+352"
    // },
    //
    // {
    //     "countryCode": "MK",
    //     "countryName": "馬其頓",
    //     "phoneCode": "+389"
    // },
    // {
    //     "countryCode": "MG",
    //     "countryName": "馬達加斯加",
    //     "phoneCode": "+261"
    // },
    // {
    //     "countryCode": "ML",
    //     "countryName": "馬來西亞",
    //     "phoneCode": "+60"
    // },
    // {
    //     "countryCode": "MV",
    //     "countryName": "馬爾地夫",
    //     "phoneCode": "+960"
    // },
    // {
    //     "countryCode": "MX",
    //     "countryName": "墨西哥",
    //     "phoneCode": "+52"
    // },
    // {
    //     "countryCode": "MA",
    //     "countryName": "摩洛哥",
    //     "phoneCode": "+212"
    // },
    // {
    //     "countryCode": "NO",
    //     "countryName": "挪威",
    //     "phoneCode": "+47"
    // },
    // {
    //     "countryCode": "NR",
    //     "countryName": "諾魯",
    //     "phoneCode": "+674"
    // },
    //
    // {
    //     "countryCode": "NI",
    //     "countryName": "尼加拉瓜",
    //     "phoneCode": "+505"
    // },
    // {
    //     "countryCode": "NG",
    //     "countryName": "奈及利亞",
    //     "phoneCode": "+234"
    // },
    // {
    //     "countryCode": "PK",
    //     "countryName": "巴基斯坦",
    //     "phoneCode": "+92"
    // },
    // {
    //     "countryCode": "PA",
    //     "countryName": "巴拿馬",
    //     "phoneCode": "+507"
    // },
    // {
    //     "countryCode": "PG",
    //     "countryName": "巴布亞紐幾內亞",
    //     "phoneCode": "+675"
    // },
    // {
    //     "countryCode": "PT",
    //     "countryName": "葡萄牙",
    //     "phoneCode": "+351"
    // },
    // {
    //     "countryCode": "PY",
    //     "countryName": "巴拉圭",
    //     "phoneCode": "+595"
    // },
    // {
    //     "countryCode": "RO",
    //     "countryName": "羅馬尼亞",
    //     "phoneCode": "+40"
    // },
    // {
    //     "countryCode": "RU",
    //     "countryName": "俄羅斯",
    //     "phoneCode": "+7"
    // },
    // {
    //     "countryCode": "RW",
    //     "countryName": "盧旺達",
    //     "phoneCode": "+250"
    // },
    // {
    //     "countryCode": "SA",
    //     "countryName": "沙烏地阿拉伯",
    //     "phoneCode": "+966"
    // },
    // {
    //     "countryCode": "SY",
    //     "countryName": "敘利亞",
    //     "phoneCode": "+381"
    // },
    // {
    //     "countryCode": "SC",
    //     "countryName": "塞席爾",
    //     "phoneCode": "+248"
    // },
    // {
    //     "countryCode": "LK",
    //     "countryName": "斯里蘭卡",
    //     "phoneCode": "+94"
    // },
    //
    // {
    //     "countryCode": "SD",
    //     "countryName": "蘇丹",
    //     "phoneCode": "+249"
    // },
    // {
    //     "countryCode": "SE",
    //     "countryName": "瑞典",
    //     "phoneCode": "+46"
    // },
    // {
    //     "countryCode": "TL",
    //     "countryName": "泰國",
    //     "phoneCode": "+66"
    // },
    // {
    //     "countryCode": "TO",
    //     "countryName": "湯加群島",
    //     "phoneCode": "+676"
    // },
    // {
    //     "countryCode": "TR",
    //     "countryName": "土耳其",
    //     "phoneCode": "+90"
    // },
    // {
    //     "countryCode": "UG",
    //     "countryName": "烏干達",
    //     "phoneCode": "+256"
    // },
    // {
    //     "countryCode": "UA",
    //     "countryName": "烏克蘭",
    //     "phoneCode": "+380"
    // },
    // {
    //     "countryCode": "AE",
    //     "countryName": "阿拉伯聯合大公國",
    //     "phoneCode": "+971"
    // },
    // {
    //     "countryCode": "UY",
    //     "countryName": "烏拉圭",
    //     "phoneCode": "+598"
    // },
    // {
    //     "countryCode": "UZ",
    //     "countryName": "烏茲別克",
    //     "phoneCode": "+998"
    // },
    // {
    //     "countryCode": "VE",
    //     "countryName": "委內瑞拉",
    //     "phoneCode": "+58"
    // },
    // {
    //     "countryCode": "YE",
    //     "countryName": "葉門",
    //     "phoneCode": "+967"
    // }
];

export const constStyleList: any = [
    {label: "彩色相片", value: "彩色相片"},
    {label: "黑白相片", value: "黑白相片"},
    {label: "油畫", value: "油畫"},

    {label: "極簡插畫風格", value: "極簡插畫風格"},
    {label: "中國水墨風格", value: "中國水墨風格"},
    {label: "包浩斯藝術風格", value: "包浩斯藝術風格"},
    {label: "日本浮世繪風格", value: "日本浮世繪風格"},
    {label: "摺紙風格", value: "摺紙風格"},
    {label: "素描風格", value: "素描風格"},
]
