import {IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRouterOutlet, IonRow, IonTabBar, IonTabButton, IonTabs, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_Pg_5_2, fetch_Pg_5_3, Pg_5_3_Store} from "../store/Pg_5_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {useParams} from "react-router";
import {Route} from "react-router-dom";
import {home, chatbubble, person, search, checkmarkOutline, chevronDownCircleOutline} from "ionicons/icons";
import Pg_5_1 from "./Pg_5_1";
import Pg_5_2 from "./Pg_5_2";
import {TokenEnwDetailItem} from "../components/TokenEnwDetailItem";
import {ProductModal} from "../components/ProductModal";
import {Pg_5_3__Buy} from "./Pg_5_3__Buy";
import {handleCommonFetchErrors} from "./Utils";
import CustomPage from "./CustomPage";
import styles_5_2 from "./Pg_5_2.module.scss";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";

const Pg_5_3 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_5_1";
    const pageId = "Pg_5_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    const {tokenEnwEntrustId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_5_3({
                p1: tokenEnwEntrustId
            });
            handleCommonFetchErrors(router, errorObj);
        })();

    }, [tokenEnwEntrustId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_Pg_5_2({
            p1: tokenEnwEntrustId
        });
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }


    const pgData = useStoreState(Pg_5_3_Store, getPageData);
    //const saved = useStoreState(QuoteStore, getSavedQuotes);
    const [amountLoaded, setAmountLoaded] = useState(20);

    const fetchMore = async e => {
        setAmountLoaded(amountLoaded => amountLoaded + 20);
        e.target.complete();
    }

    const handleShowModal = product => {
        setSelectedProduct(product);
        present({
            cssClass: "product-modal",
            presentingElement: pageRef.current
        });
    }

    const [selectedProduct, setSelectedProduct] = useState(false);
    const [present, dismiss] = useIonModal(Pg_5_3__Buy, {
        dismiss: () => dismiss(),
        product: selectedProduct
    });


    const hasLoaded = pgData?.Root ?? false;
    const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;
    const List_TokenEnw委託二手交易 = hasLoaded ? pgData.Root.List_TokenEnw委託二手交易 : undefined;

    //const categories = Object.keys(productInfo);

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    (List_TokenEnw委託二手交易).map((Item_TokenEnw委託二手交易)=>{
                        let Item_TokenEnw = Item_TokenEnw委託二手交易._TokenEnw;
                        let Item_Token = Item_TokenEnw._Token;

                        return <>
                            <IonCard className="animate__animated animate__slideInRight animate__faster">
                                <IonImg src={"https://via.placeholder.com/300x90.png?text=ContractImage"} alt="quote cover"/>
                                {/*<IonCardContent>*/}
                                {/*    <h1>{Item_會員.登入Uid}</h1>*/}
                                {/*    <p>{Item_會員.個人介紹}</p>*/}
                                {/*</IonCardContent>*/}
                                <IonCol size="6">
                                    <IonButton fill={"solid"} onClick={() => handleShowModal({})}>
                                        <IonIcon icon={checkmarkOutline}/>
                                        &nbsp;購買(Pg_5_3__Buy)
                                    </IonButton>

                                    {Item_TokenEnw委託二手交易.價錢} 寶石
                                </IonCol>

                                <IonRow>
                                    <IonCol size="6">
                                        {/*<IonButton fill={ !bookmarked ? "outline" : "solid" } onClick={ () => bookmarked ? removeSavedQuote(quote.id) : addSavedQuote(quote.id) }>*/}
                                        {/*    <IonIcon icon={ bookmarked ? checkmarkOutline : bookmarkOutline } />*/}
                                        {/*    &nbsp;{ bookmarked ? "Bookmarked" : "Save as Bookmark" }*/}
                                        {/*</IonButton>*/}
                                    </IonCol>

                                    {/*<IonCol size="4">*/}
                                    {/*    <IonButton fill="outline">*/}
                                    {/*        <IonIcon icon={copyOutline}/>*/}
                                    {/*        &nbsp;Copy Quote*/}
                                    {/*    </IonButton>*/}
                                    {/*</IonCol>*/}
                                </IonRow>
                            </IonCard>

                            <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                <IonRow>
                                    <IonCol size="12">
                                        <IonText color="dark">
                                            <p className="title">{Item_TokenEnw._Token.名稱}</p>
                                            <p>{Item_TokenEnw._Token.介紹}</p>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonGrid className="ion-padding">
                                    {/*<IonRow className={ styles.searchContainer }>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}


                                    {/*click={() => handleShowModal(item)}*/}
                                    <IonRow>
                                        <TokenEnwDetailItem Item_TokenEnw={Item_TokenEnw}/>
                                    </IonRow>
                                </IonGrid>
                            </IonGrid>
                        </>
                    })
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_5_3;
