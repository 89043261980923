import {IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLabel, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';

import styles from "./Categories.module.scss";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {Fragment, useEffect, useRef, useState} from "react";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {PageHeader} from "./PageHeader";
import {TokenItem} from "../components/TokenItem";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";
import {useStoreState} from "pullstate";
import {getPageData} from "../store/Selectors";
import {fetch_pg_4_4, Pg_4_4_Store} from "../store/Pg_4_Store";
import queryString from "query-string";
import {ContractBonusItem} from "../components/ContractBonusItem";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {useParams} from "react-router";
import {chevronDownCircleOutline} from "ionicons/icons";
import {ContractBonusDetailItem} from "../components/ContractBonusDetailItem";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleCommonFetchErrors, promptBrowserToLogin} from "./Utils";
import {AuthStore} from "../store/Common_Store";

const Pg_4_4 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_4_4";
    const pageId = "Pg_4_4";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    ///#endregion

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {contractBonusId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_4_4({
                p1: contractBonusId,
            });
            handleCommonFetchErrors(router, errorObj);
        })();
    }, [contractBonusId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_4_4({
            p1: contractBonusId,
        });
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_4_4_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const Item_合約獎勵 = hasLoaded ? pgData.Root.Item_合約獎勵 : undefined;
    const 已兌換次數 = hasLoaded ? pgData.Root.已兌換次數 : undefined;
    const 總共可兌換次數 = hasLoaded ? pgData.Root.總共可兌換次數 : undefined;
    const QrCode = hasLoaded ? pgData.Root.QrCode : undefined;

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid className="ion-padding">
                            {/*<IonRow className={ styles.searchContainer }>*/}
                            {/*    <IonCol size="12">*/}
                            {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}


                            {/*click={() => handleShowModal(item)}*/}
                            <IonRow>
                                <ContractBonusDetailItem Item_合約獎勵={Item_合約獎勵} QrCode={QrCode} 已兌換次數={已兌換次數} 總共可兌換次數={總共可兌換次數} noLink={true} onClickCapture={(ev)=> {
                                    if (!hasAuthed) {
                                        promptBrowserToLogin();
                                        ev.stopPropagation();
                                        return false;
                                    }
                                    return true;
                                }}
                                />

                                {/*{List_合約獎勵.map((item, index) => {*/}
                                {/*    return <Fragment key={item.合約獎勵Id}>*/}
                                {/*        */}
                                {/*    </Fragment>*/}
                                {/*})}*/}
                            </IonRow>
                        </IonGrid>
                    </>}
            </CustomPage>
        </IonPage>
    );
};

export default Pg_4_4;
