import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter, IonImg} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import styles_Modal_2_3_OpenCamera from './Modal_2_3_OpenCamera.module.scss';
import {push_pg_2_3__Entrust, push_pg_2_3__Transfer} from "../store/Pg_2_Store";
import {handleCommonFetchErrors} from "./Utils";
import styles_1_6 from "./Pg_1_6.module.scss";
import {ActionLink} from "../components/ActionLink";


export const Modal_2_3_OpenCamera = ({tokenEnwId, dismiss, onResult}) => {
    const router = useIonRouter();
    let [mode, setMode] = useState(1);

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion

    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>下載元宇宙相機</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-text-center ion-margin-top">
                    <IonCol>
                        請先下載元宇宙相機APP，並使用PetJourney的帳密登入
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonGrid className={`${styles_Modal_2_3_OpenCamera.appicons}`}>
                            <ActionLink link={`https://apps.apple.com/tw/app/metaverse-camera/id6447341037`} target="_blank">
                                <div className={styles_Modal_2_3_OpenCamera.half}>
                                    <IonImg src={"/assets/apple.22f8fdd.png"} alt="ios-app download"/>
                                </div>
                            </ActionLink>
                            <ActionLink link={`https://play.google.com/store/apps/details?id=com.yassartlabs.MetaverseCamera`} target="_blank">
                                <div className={styles_Modal_2_3_OpenCamera.half}>
                                    <IonImg src={"/assets/android.650db94.png"} alt="android-app download"/>
                                </div>
                            </ActionLink>
                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </>;
};
